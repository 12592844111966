<template>
    <div v-if="similarStartups.length > 0">

        <div class="dropdown-similar-startups">
            <b-button v-b-toggle.collapse-1 variant="primary">View Similar Startups</b-button>

            <b-collapse id="collapse-1" visible>
                <ul class="list-companies" v-if="!loading">
                    <li v-for="similarStartup in similarStartups.slice(0, 7)" :key="similarStartup.id">
                        <a
                            rel="noreferrer"
                            class="image-block"
                            target="_blank"
                            v-b-tooltip:hover
                            :href="`/startups/${similarStartup.id}`"
                            :title="similarStartup.name"
                        >
                            <img :src="LogoHelper.getLogo(similarStartup.image)" :alt="similarStartup.name">
                        </a>
                    </li>

                    <li class="list-companies__more" v-if="similarStartups.length > 7">
                        <a href="javascript:void(0)" @click="openSimilarStartupsModal">+{{ similarStartups.slice(7).length }}</a>
                    </li>
                </ul>

                <similar-startups ref="similarStartupsModal"/>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import search from "@/services/search.service";
import SimilarStartups from "@/components/startups/SimilarStartups.vue";

export default ({
    name: 'AsideSimilarCompanies',
    props: ['startupId'],

    components: {
        SimilarStartups
    },

    data() {
        return {
            loading: true,
            similarStartups: []
        }
    },

    mounted() {
        void this.fetchData();
    },

    methods: {
        openSimilarStartupsModal() {
            this.$refs.similarStartupsModal.open(this.startupId);
        },

        async fetchData() {
            this.loading = true;

            const response = await search.getSimilar({ searchType: 'startups', companyId: this.startupId });
            this.similarStartups = response.data;
            this.loading = false;
        }
    },
})
</script>
