<template>
    <div>
        <input :class="inputClass" :placeholder="placeHolder"
               v-debounce:500="updateOptions"
               v-model="inputText"
               @input="$emit('input', $event.target.value)"
               @keyup.enter="submitInput"
               @keyup.down="nextItem"
               @keyup.up="prevItem"
               @keyup.esc="items = []"
        >

        <div class="topbar__key__autocomplete">
            <ul v-if="items.length" class="topbar__key__autocomplete__list" v-click-outside="function () {items = []; oldInputText = '';}">
                <li :class="{'selected': currentItem === (index + 1)}" @click="itemClicked(item)" v-for="(item, index) in items" :key="item.id">{{item.name}}</li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        clearOnSubmit: Boolean,
        placeHolder: String,
        value: String,
        updateFunction: Function,
        inputClass: String,
        exclude: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            inputText: '',
            oldInputText: '',
            items: [],
            currentItem: 0,
        }
    },

    methods: {
        setValue(value){
            this.inputText = value
        },

        updateOptions() {
            if (this.oldInputText !== this.inputText) {

                this.items = []
                if (this.inputText.length) {
                    this.updateFunction(this.inputText).then(response => {
                        if (this.inputText.length) {
                            this.items = response.data.filter(item => !this.exclude.some(excludeId => excludeId === item.id))
                        }
                    })
                }

                this.oldInputText = this.inputText
            }
        },

        nextItem() {
            if (this.items.length) {
                if (this.items.length > this.currentItem) {
                    ++this.currentItem
                } else {
                    this.currentItem = 1
                }
            }
        },

        prevItem() {
            if (this.items.length) {
                if (this.currentItem > 1) {
                    --this.currentItem
                } else {
                    this.currentItem = this.items.length
                }
            }
        },

        itemClicked(selected) {
            this.$emit('submitted', selected.id)

            if (this.clearOnSubmit) {
                this.inputText = ""
                this.oldInputText = ""
            } else {
                this.inputText = selected.name
                this.oldInputText = selected.name
            }

            this.items = []
        },

        submitInput() {
            let text = ""

            if (this.currentItem > 0) {
                let selected = this.items[this.currentItem - 1]
                this.$emit('submitted', selected.id)
                text = selected.name
            }
            else {
                this.$emit('submitted', this.inputText)
            }

            this.items = []
            this.currentItem = 0

            if (this.clearOnSubmit) {
                this.inputText = ""
            } else {
                this.inputText = text
                this.oldInputText = text
            }

        },

        clear() {
            this.inputText = ""
        }
    }
}
</script>
