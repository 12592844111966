<template>
    <b-modal id="modal-duplicate-tracker" title="Duplicate Tracker" modal-class="modal-w-md">
        
        <h6 class="heading6 mb-1">Tracker Name*</h6>
        <b-form-input class="form-control--mid mb-3" v-model="tracker.name"></b-form-input>
        
        <template #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave || requestInProgress" @click="save">Create Duplicate Tracker</b-button>
        </template>
    </b-modal>
</template>

<script>
import trackers from "@/services/trackers.service"
import Vue from "vue";

export default {
    data() {
        return {
            id: 0,

            tracker: {
                name: []
            },

            requestInProgress: false
        }
    },

    computed: {
        canSave() {
            return this.tracker.name.length
        }
    },

    methods: {
        open(id, name) {
            this.id = id
            this.tracker.name = "Duplicate of " + name

            if (this.$store.state.accountType != 'premium') {
                trackers.getCount().then(response => {
                    if (response.data >= this.constants.enterpriseLimits.trackers[this.$store.state.accountType]) {
                        this.showNeedUpgrade('trackers')
                    } else {
                        this.$bvModal.show('modal-duplicate-tracker')
                    }
                })
            }
            else {
                this.$bvModal.show('modal-duplicate-tracker')
            }
        },

        save() {
            this.requestInProgress = true

            trackers.duplicate(this.id, this.tracker).then((response) => {
                let id = response.data

                if (id) {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 10000,
                        text: "New tracker created: <a class='btn btn-outline-success btn-notify btn-lg ml-2' href='/trackers/" + id + "' target='_blank'>" + this.tracker.name + "</a>",
                    })

                    this.$emit('trackerSaved')
                } else {
                    Vue.notify({
                        group: 'app',
                        type: 'error',
                        duration: 10000,
                        text: "Tracker was not created",
                    })
                }

                this.$bvModal.hide('modal-duplicate-tracker')
                this.requestInProgress = false
            })
        },
    }
}
</script>

<style scoped>

</style>
