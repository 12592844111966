<template>
    <div class="main-inner">
        <div class="categories-section">
            <label class="categories-section__label">Categories:</label>
            <multiselect
                v-model="selectedCategories"
                :multiple="true"
                :options="$store.state.categories"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                class="multiselect-mid"
            >
            </multiselect>
    
            <date-picker v-model="period" range></date-picker>
        </div>

        <p v-if="trackersLoaded && !trackers.length">
            No public challenges match your filters.
        </p>

        <div v-for="tracker in trackers" :key="tracker.id" class="card card--bordered card--row">
            <div class="image-block image-block--lg">
                <img :src="LogoHelper.getLogo(tracker.company_logo)" alt="img">
            </div>
            
            <div class="card--row__content">
                <h4 class="heading4 heading4--secondary">{{tracker.name}}</h4>
    
                <p v-html="tracker.description" class="line-clamp line-clamp3"></p>
            </div>

            <template v-if="tracker.applied">
                <button class="btn btn-secondary btn-lg">Applied</button>
            </template>
            <router-link v-else :to="'/public-trackers/' + tracker.id" class="btn btn-primary btn-lg">Apply To Challenge</router-link>

        </div>

    </div>
</template>

<script>
import trackers from "@/services/trackers.service";
import multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        multiselect,
        DatePicker,
    },

    data() {
        return {
            trackers: [],
            trackersLoaded: false,
            filter: {
                categories: [],
                period: [null, null],
            },

            period: [null, null],
            selectedCategories: [],
        }
    },

    watch: {
        filter: {
            deep: true,
            handler() {
                this.getList()
            }
        },

        selectedCategories() {
            this.filter.categories = this.arrayHelper.arrayColumn(this.selectedCategories, "id")
        },

        period() {
            this.filter.period = [
                this.dateHelper.encodeDateForServer(this.period[0]),
                this.dateHelper.encodeDateForServer(this.period[1]),
            ]
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        getList() {
            trackers.getTrackersForApply({filter: this.filter}).then(response => {
                this.trackers = response.data;
                this.trackersLoaded = true;
            })
        },
    }
}
</script>

<style scoped>

</style>
