<template>
    <div>
        <Header/>
        <search search-type="startups" customView="my-startups"/>
    </div>
</template>

<script>
import Header from "@/components/startups/Header";
import Search from "@/views/Search.vue";

export default {
    components: {
        Search,
        Header,
    },
}
</script>

<style scoped>

</style>
