import axios from '@/axios';

const RESOURCE_NAME = '/saved-searches';

export default {
    getList(searchType) {
        return axios.get(`${RESOURCE_NAME}/get-list?search_type=${searchType}`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, {data: data})
    },

    setAutoScout(id, data) {
        return axios.post(`${RESOURCE_NAME}/set-auto-scout?id=${id}`, {data: data})
    },

    updateQuery(id, rules) {
        return axios.post(`${RESOURCE_NAME}/update-query?id=${id}`, {rules: rules})
    },

    deleteSearch(id) {
        return axios.post(`${RESOURCE_NAME}/delete-search?id=${id}`)
    },

    updateSearched(id) {
        axios.post(`${RESOURCE_NAME}/update-searched?id=${id}`)
    },

    globalSavedSearchNotificationsEnabled() {
        return axios.get(`${RESOURCE_NAME}/global-saved-search-notifications-enabled`)
    },
}
