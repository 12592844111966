<template>
    <form class="form form--enter pb-4" @submit.prevent="checkEmail">
        <div class="form--enter__container">
            <fieldset class="form--enter__fieldset">
                <div v-for="error in errors" :key="error">
                    <span class="form__error">{{error}}</span>
                </div>
                <div class="form__item">
                    <label class="form__label pl-0">Email</label>
                    <input id="username" name="username" class="form-control--mid form-control" required v-model="username" type="text"/>
                </div>

                <template v-if="showPassword">
                    <div class="form__item">
                        <label class="form__label pl-0">Password</label>
                        <input id="password" name="password" class="form-control--mid form-control" required v-model="password" type="password"/>
                    </div>

                    <div class="d-flex justify-content-between mb-4">
                        <p class="mb-0"><router-link :to="resetPasswordLink">Forgot password?</router-link></p>
                    </div>

                    <div class="text-center">
                        <button class="button" type="submit" :disabled="this.loading">
                            <b-spinner v-if="loading" class="mr-1" small></b-spinner>
                            Log In
                        </button>
                    </div>
                </template>

                <template v-else>
                    <div class="text-center">
                        <button class="button" type="button" @click="checkEmail">Next</button>
                    </div>
                </template>
            </fieldset>
        </div>
    </form>
</template>

<script>
import auth from "@/services/auth.service";
import redirectRules from "@/constants/redirect-rules";
import { baseURL } from "@/axios";

export default {
    props: {
        fromTracker: {
            type: String,
            default: "",
        }
    },

    data () {
        return {
            username: "",
            password: "",
            errors: [],
            showPassword: process.env.NODE_ENV == 'development',
            loading:false,
        }
    },

    watch: {
        username() {
            let domainParts = this.username.split('@')
            if (domainParts && domainParts.length == 2 && domainParts[1].length > 2) {
                let domainName = domainParts[1]
                let matches = false
                for (let ssoDomain in redirectRules.rules) {
                    if (ssoDomain.indexOf(domainName) >= 0) {
                        matches = true
                        break
                    }
                }

                if (!matches) {
                    this.showPassword = true
                }
                else {
                    this.showPassword = false
                }
            }
            else {
                this.showPassword = false
            }
        }
    },

    computed: {
        resetPasswordLink() {
            if (this.fromTracker) {
                return "/reset-password?tracker_id=" + this.fromTracker
            }
            else {
                return "/reset-password"
            }
        }
    },

    methods: {
        checkEmail() {
            if (this.showPassword) {
                this.login()
            }
            else {
                let domainParts = this.username.split('@')
                if (domainParts.length == 2) {
                    let domainName = domainParts[1]

                    if (typeof redirectRules.rules[domainName] !== 'undefined' && redirectRules.exceptions.indexOf(this.username) < 0 && process.env.NODE_ENV == 'production') {
                        window.location.href = baseURL + redirectRules.rules[domainName]
                    } else {
                        this.showPassword = true
                    }
                }
            }
        },

        login() {
            this.loading = true
            let username = this.username
            let password = this.password

            auth.login({ username, password })
                .then(response => {
                    this.loading = false
                    this.errors = response.data.errors

                    if (this.errors.length) {
                        this.$store.commit('authError')
                    }
                    else if (response.data.user.token) {
                        this.$store.commit('authSuccess', response.data.user)

                        let trialExpired = false
                        if (typeof response.data.trial_expired != 'undefined') {
                            trialExpired = response.data.trial_expired
                        }

                        this.$emit('login', {showProfile: response.data.show_profile, trialExpired: trialExpired})
                    }
                })
                .catch(error => {
                    this.loading = false
                    this.$store.commit('authError')
                    console.log(error)
                })
        }
    }
}
</script>

<style scoped>

</style>
