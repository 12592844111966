<template>
    <div>
        <b-modal id="modal-need-upgrade" title="" hide-footer size="xl">

            <template v-if="type === 'ecosystems-connections'">
                <h1 class="heading1">Upgrade to keep connecting</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can only accept ecosystem invites on your current plan
                </p>

            </template>

            <template v-if="type === 'startups-search'">
                <h1 class="heading1">Upgrade to discover more startups</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can only view startups shared with you on your current plan
                </p>
            </template>

            <template v-if="type === 'ecosystems-invites'">
                <h1 class="heading1">You can only invite 3 ecosystems on your current plan</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can only accept ecosystem invites on your current plan
                </p>

            </template>

            <template v-if="type === 'trackers'">
                <h1 class="heading1">Upgrade to keep exploring</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can only create 3 trackers on your current plan
                </p>
            </template>

            <template v-if="type === 'users'">
                <h1 class="heading1">Upgrade to collaborate more</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can only have 2 users on your current plan
                </p>
            </template>

            <template v-if="type === 'activity'">
                <h1 class="heading1">Upgrade to stay up to date</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You don't have access to activity on your current plan
                </p>
            </template>

            <template v-if="type === 'tasks'">
                <h1 class="heading1">Upgrade to assign tasks</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can't view or create tasks on your current plan
                </p>
            </template>

            <template v-if="type === 'startups-add'">
                <h1 class="heading1">Upgrade your account to add startups</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You can only see startups added by ecosystems
                </p>
            </template>
        
            <template v-if="type === 'insights'">
                <h1 class="heading1">Upgrade to stay up to date</h1>

                <p class="text-title" v-if="$store.state.accountType === 'free'">
                    You don't have access to activity on your current plan
                </p>
            </template>

            <div class="account-page">
                <div class="account-plan">
                    <div class="account-plan__item">
                        <div class="account-plan__item__wrapper">
                            <h3 class="heading3">Free Access</h3>

                            <ul class="account-plan__list-check">
                                <li v-for="(item, index) in constants.enterpriseAccountTypesFeatures['free']" :key="index" :class="{'account-plan__list-check__selected': activeItem !== 2 && index === activeItem}">{{item}}</li>
                            </ul>
                        </div>

                        <footer class="account-plan__item__footer">
                            <button v-if="$store.state.accountType === 'free'" @click="close" class="button">Keep This Plan</button>
                        </footer>
                    </div>

                    <div class="account-plan__item account-plan__item--premium">

                        <div class="account-plan__item__wrapper">
                            <h3 class="heading3">Premium Access</h3>

                            <ul class="account-plan__list-check">
                                <li v-for="(item, index) in constants.enterpriseAccountTypesFeatures['premium']" :key="index" :class="{'account-plan__list-check__selected': index === activeItem}">{{item}}</li>
                            </ul>
                        </div>

                        <footer class="account-plan__item__footer">
                            <p>{{constants.enterpriseAccountTypesPrices['premium']}}</p>

                            <div v-if="$store.state.accountType !==  'premium'" class="buttons-wrapper">
                                <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>

export default {
    data() {
        return {
            type: String,
        }
    },

    computed: {
        activeItem() {
            if (this.type === 'ecosystems-connections') {
                return 0
            }
            else if (this.type === 'startups-search') {
                return 1
            }
            else if (this.type === 'insights') {
                return 2
            }
            else if (this.type === 'trackers') {
                return 3
            }
            else if (this.type === 'users') {
                return 4
            }
            else if (this.type === 'activity') {
                return 5
            }
            else if (this.type === 'tasks') {
                return 6
            }
            else if (this.type === 'startups-add') {
                return 7
            }
            else {
                return -1
            }
        }
    },

    mounted() {
        this.$store.watch(state => {
            if (state.companyType === 'enterprise' && state.showNeedUpgrade) {
                this.type = state.showNeedUpgrade

                if (this.$store.state.accountType === 'premium') {
                    this.$bvModal.show('modal-need-add-costs')
                }
                else {
                    this.$bvModal.show('modal-need-upgrade')
                }
            }
        })
    },

    methods: {
        close() {
            this.$bvModal.hide('modal-need-upgrade')
        },

        upgrade(type) {
            this.$bvModal.hide('modal-need-upgrade')
            this.showUpgrade(type)
        }
    }
}
</script>
