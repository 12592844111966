<template>
    <div>
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Admin</h2>
        </div>
        
        <div class="admin-page">
            <Header></Header>
            
            <div class="admin-page__content">
                
                <dl class="definition-list">
                    <dt class="">
                        Account Type
                    </dt>
                    <dd class="">
                        <div class="d-flex">
                            {{constants.enterpriseAccountTypes[$store.state.realAccountType]}}
                            <a v-if="false" href="" class="link-edit link-edit--secondary ml-2"></a>
                        </div>
        
                        <!--<h5 class="heading5 heading5--secondary">Account Features</h5>>
                        <ul class="list-dotted account-plan__list">
                            <li v-for="(item, index) in constants.enterpriseAccountTypesFeatures[$store.state.accountType]" :key="index">{{item}}</li>
                        </ul-->
                    </dd>
                    
                    <dt>
                        Features
                    </dt>
                    <dd>
                        <ul class="list-dotted account-plan__list-check">
                            <li v-for="(item, index) in constants.enterpriseAccountTypesFeatures[$store.state.accountType]" :key="index">{{item}}</li>
                        </ul>
    
                        <!--footer class="account-plan__item__footer">
                            <p>{{constants.enterpriseAccountTypesPrices['premium']}}</p>
                            <div class="buttons-wrapper">
                                <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
                            </div>
                        </footer-->
                    </dd>
                </dl>
                
                <div class="account-page">
                    <!--header class="account-page__header">
                        <h3 class="heading3">Account</h3>
                    </header-->
        
                    <!--div class="account-plan">
                        <div class="account-plan__item account-plan__item--active">
                            <h5 class="heading5 heading5--secondary">Account Type</h5>
        
                            <input class="account-plan__input" disabled :value="constants.enterpriseAccountTypes[$store.state.accountType]">
        
                            <h5 class="heading5 heading5--secondary">Account Features</h5>
                            <ul class="account-plan__list">
                                <li v-for="(item, index) in constants.enterpriseAccountTypesFeatures[$store.state.accountType]" :key="index">{{item}}</li>
                            </ul>
                        </div>
        
                        <div class="account-plan__item account-plan__item--premium" v-if="$store.state.accountType != 'premium'">
                            <h3 class="heading3">Premium Access</h3>
        
                            <ul class="account-plan__list-check">
                                <li v-for="(item, index) in constants.enterpriseAccountTypesFeatures['premium']" :key="index">{{item}}</li>
                            </ul>
        
                            <footer class="account-plan__item__footer">
                                <p>{{constants.enterpriseAccountTypesPrices['premium']}}</p>
                                <div class="buttons-wrapper">
                                    <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
                                </div>
                            </footer>
                        </div>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/settings/admin/AdminHeader";

export default {
    components: {
        //Title,
        Header,
    },
}
</script>

<style scoped>

</style>
