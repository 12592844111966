<template>
    <div>
        <div class="topbar topbar__dashboard">
            <div class="topbar__dashboard__inner">
                <div class="row align-items-baseline">

                    <div class="col-12 d-flex flex-wrap">
                        <div class="bullet-section">
                            <a @click="editFilters">
                                <span class="bullet bullet--period">
                                    {{periodLabel}}
                                </span>
                            </a>

                            <a @click="editFilters">
                                <span class="bullet bullet--users">
                                    {{userTypeLabel}}
                                </span>
                            </a>

                            <a @click="editFilters" v-for="tracker in filters.trackers" :key="tracker.id">
                                <span class="bullet bullet--tracker">
                                    {{tracker.name}}
                                </span>
                            </a>

                            <a @click="editFilters" v-for="tag in filters.tags" :key="tag.id">
                                <span class="bullet bullet--tag">
                                    {{tag.name}}
                                </span>
                            </a>

                            <a @click="editFilters">
                                <span class="bullet bullet--fields" v-if="filters.customFields.length">
                                    {{filters.customFields.length}} selected
                                </span>
                            </a>
                        </div>

                        <ul class="topbar__side actions-list topbar__side--home">
                            <li class="topbar__side__actions--filter"><a @click="editFilters">Edit Filters</a></li>

                            <li v-if="showSaveFilter"><a class="topbar__side__save topbar__side__save--big" @click="openSaveFilter">Save As Preset</a></li>

                            <template v-if="showPresets">
                                <li>
                                    <b-dropdown v-if="savedFiltersList.length" :text="loadPresetLabel" class="topbar__side__actions topbar__presets">
                                        <b-dropdown-item v-if="this.savedFilter" @click="resetFilters" class="default-preset">
                                            Reset To Default
                                        </b-dropdown-item>

                                        <b-dropdown-item v-for="(item, index) in savedFiltersList" @click="savedFilter = item" :key="index">
                                            {{item.name}}
                                        </b-dropdown-item>

                                        <b-dropdown-item class="manage-presets" @click="openManageSavedFilters">
                                            Manage Saved Presets
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </li>

                                <li v-if="$store.state.user.is_admin">
                                    <a @click="$refs.customizeHomepage.open()" class="link link--dashboard">Customize Homepage</a>
                                </li>
                            </template>

                            <template v-else>
                                <li>
                                    <a @click="resetFilters" class="topbar__side__clear">Clear Selected</a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <main class="main main--dark main--dashboard">
            <div class="content">

                <lessons-home-widget v-if="!$store.getters.isFreeEnterprise && $store.state.user.show_lessons_widget"/>

                <div v-for="(section, index) in layout" :key="index">
                    <dashboard v-if="section === 'dashboard' && canLoad"
                               :filters="filterForServer"
                               @customize="customizeDashboard"
                    />

                    <tasks v-if="section === 'tasks'" v-on:loaded="loadAfterTasks"/>
                    <startups v-if="section === 'startups'" :filters="filterForServer"/>
                    <searches v-if="section === 'searches'" :filters="filterForServer"/>
                    <trackers v-if="section === 'trackers'" :filters="filterForServer"/>
                    <users v-if="section === 'users'" ref="users" :filters="filterForServer"/>
                </div>
            </div>

            <aside class="aside">
                <div class="aside__inner">
                    <div class="aside__inner__section">
                        <div class="image-block-wrapper align-items-start">
                            <div class="image-block image-block--mds mb-3">
                                <img :src="LogoHelper.getLogo($store.state.user.company_logo)" alt="img"/>
                            </div>
                            <div>
                                <h4 class="heading4">{{ $store.state.user.company_name }}</h4>
                                <p class="m-0 mb-4"><a :href="$store.state.user.company_url" target="_blank">{{$store.state.user.company_website}}</a></p>
                            </div>
                        </div>

                        <div class="card card--bordered">
                            <p class="knowledgebase-text">Learn how to use SwitchPitch features</p>
                            <a class="button button--bordered--without--icon" href="https://switchpitch.document360.io/" target="_blank">
                                View Knowledgebase
                            </a>
                        </div>

                        <manager-block
                            v-if="$store.state.user.company_id !== constants.companyPfizer"
                        />

                        <!--            <p>Founded on 2012 | Enterprise <a href="#">switchpitch.com</a></p>-->
                        <!--            <p>Washington, District Of Columbia, 20007, United States, North America</p>-->
                        <!--            <p>SwitchPitch Profile Public URL: <a href="#">connect.switchpitch.com/companylinknamegoeshere/32324</a></p>-->

                        <div v-if="$store.state.accountType !== 'free' && analystHours.loaded && analystHours.hasAnalyst" class="block-hours">

                            <header class="block-hours__header">
                                <h4 class="heading4">Analyst Hours</h4>
                                <p>{{analystHours.left}}h remaining</p>
                            </header>

                            <p v-if="!analystHours.left" class="text-center">You have no analyst hours.</p>

                            <div class="progress-wrapper" v-if="analystHours.percents">
                                <span class="time-badge" :class="'time-badge--' + analystHours.plan"></span>
                                <div class="progress">
                                    <div class="progress-bar" :class="'progress-bar--' + analystHoursPercentsColor" role="progressbar" :style="{'width': analystHours.percents + '%'}" :aria-valuenow="analystHours.percents" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <button class="button button--hours button--bordered--with--icon mb-2" @click="addAnalystHours">Add Analyst Hours</button>
                            <button v-if="analystHours.left" @click="$refs.analystMessageModal.show()" class="button button--bordered--with--icon button--new">New Project/Contact</button>

                        </div>

                        <add-analyst-hours
                            ref="addAnalystHours"
                            v-on:analyst-hours-added="getAnalystHours"
                        />

                    </div>

                    <div v-if="lastVisitedStartups.length" class="aside__inner__section">
                        <h4 class="heading4">Recently Viewed</h4>

                        <ul class="rows-list rows-list--secondary">
                            <li v-for="(startup, index) in lastVisitedStartups" :key="index">
                                <figure class="image-block image-block--xs">
                                    <img :src="LogoHelper.getLogo(startup.logo_url)" alt="img"/>
                                </figure>
                                <div>
                                    <h5 class="heading5"><a :href="'/startups/' + startup.id" target="_blank">{{ startup.name }}</a></h5>
                                    <p>Viewed
                                        <template v-if="startup.viewed === 'today'">today</template>
                                        <template v-else>{{ startup.viewed }} ago</template>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        </main>

        <home-page-filters
            ref="filters"
            @filtersChanged="filtersChanged"
        />

        <save-filter
            ref="saveFilter"
            :filter="filterForServer"
            :filtersList="savedFiltersStructured"
            v-on:saved-filters-changed="savedFiltersChanged"
            v-on:saved-filter-created="savedFilterCreated"
        />

        <customize-homepage
            ref="customizeHomepage"
            @customizeDashboard="customizeDashboard"
        />

        <customize-dashboard
            ref="customizeDashboard"
            @modalClosed="() => {
                $refs.customizeHomepage.open()
            }"
        />

        <message-modal
            id="analyst-message-modal"
            ref="analystMessageModal"
            title="Message Analyst"
            type="analyst"
        />
    </div>
</template>

<script>

import HomePageFilters from "@/components/HomePageFilters.vue";
import {mapActions, mapGetters} from "vuex";
import savedFiltersService from "@/services/saved-filters.service";
import SaveFilter from "@/components/SaveFilter.vue";
import CustomizeHomepage from "@/views/enterprise/home/components/CustomizeHomepage";
import ManagerBlock from "@/components/ManagerBlock.vue";
import AddAnalystHours from "@/components/subscriptions/AddAnalystHours.vue";
import analyst from "@/services/analyst.service";
import dashboard from "@/services/dashboard.service";
import MessageModal from "@/components/MessageModal.vue";

const defaultPeriod = 'all';

export default {
    name: "Home",

    components: {
        MessageModal,
        AddAnalystHours, ManagerBlock,
        SaveFilter,
        HomePageFilters,
        CustomizeHomepage,
        LessonsHomeWidget: () => import("@/components/lessons/LessonsHomeWidget.vue"),
        Tasks: () => import('@/views/enterprise/home/components/Tasks'),
        Startups: () => import('@/views/enterprise/home/components/Startups'),
        Searches: () => import('@/views/enterprise/home/components/Searches'),
        Trackers: () => import('@/views/enterprise/home/components/Trackers'),
        Users: () => import("@/views/enterprise/home/components/Users"),
        Dashboard: () => import("@/views/enterprise/home/components/Dashboard"),
        CustomizeDashboard: () => import("@/views/enterprise/home/components/CustomizeDashboard"),
    },

    data() {
        return {
            filters: {
                period: this.constants.commonFilterPeriods.find(item => item.value === defaultPeriod),
                userType: this.constants.usersTypes.find(item => item.value === 'all'),
                trackers: [],
                tags: [],
                customFields: [],
            },

            canLoad: true,
            showSaveFilter: false,
            showPresets: true,
            watchFilterChange: false,
            updateDefault: true,

            savedFiltersStructured: [],
            savedFilter: null,

            analystHours: {
                loaded: false,
                hasAnalyst: false,
                left: 0,
                plan: "",
                percents: 0,
            },

            lastVisitedStartups: [],
        }
    },

    watch: {
        filters() {
            if (this.watchFilterChange) {
                this.showSaveFilter = true
                this.showPresets =  false
            }
        },

        savedFilter() {
            if (this.savedFilter) {
                this.applySavedFilter();
            }
        }
    },

    computed: {
        ...mapGetters([
            "foldersTrackersLoaded",
            "foldersTrackersList",
            "tagsList",
        ]),

        ...mapGetters({
            layout: 'homepage/layout',
        }),

        loadPresetLabel() {
            if (this.savedFilter) {
                return this.savedFilter.name
            }
            else {
                return "Load Preset"
            }
        },

        savedFiltersList() {
            return this.savedFiltersStructured.map(group => group.items).flat();
        },

        trackersList() {
            return this.foldersTrackersList.reduce((trackers, group) => [...trackers, ...group[1]], []);
        },

        filterForServer() {
            let filter = {
                period: this.filters.period.value,
                userType: this.filters.userType.value,
            };

            if (this.filters.trackers.length) {
                filter.trackers = this.filters.trackers.map(item => item.id);
            }

            if (this.filters.tags.length) {
                filter.tags = this.filters.tags.map(item => item.id);
            }

            if (this.filters.customFields.length) {
                filter.fields = this.filters.customFields;
            }

            return filter;
        },

        analystHoursPercentsColor() {
            if (this.analystHours.percents > 50) {
                return 'green'
            }
            else if (this.analystHours.percents > 20) {
                return 'yellow'
            }
            else {
                return 'red'
            }
        },

        periodLabel() {
            return this.constants.commonFilterPeriods.find(item => item.value === this.filters.period.value).label;
        },

        userTypeLabel() {
            return this.constants.usersTypes.find(item => item.value === this.filters.userType.value).label;
        },
    },

    mounted() {
        this.fetchFoldersTrackers();
        this.fetchTrackers();
        this.fetchTags();

        this.setFilters();

        this.$nextTick(() => {
            this.watchFilterChange = true;
        })

        this.$store.watch(() => {
            if (this.foldersTrackersLoaded) {
                this.getSavedFilters(0, true)
            }
        })
    },

    methods: {
        ...mapActions(['fetchFoldersTrackers','fetchTags','fetchTrackers']),

        updateAll() {
            dashboard.getWidgetData('LastVisitedStartups', this.filterForServer).then(response => {
                this.lastVisitedStartups = response.data
            })
        },

        editFilters() {
            this.$refs.filters.open();
        },

        setFilters() {
            this.$refs.filters.setFilters({...this.filters});
        },

        filtersChanged(filters) {
            this.filters = filters;
        },

        getSavedFilters(currentFilterId = 0, loadDefault = false) {
            savedFiltersService.getList().then(response => {
                this.savedFiltersStructured = response.data

                if (this.savedFiltersList.length) {
                    if (currentFilterId) {
                        this.savedFilter = this.savedFiltersList.find(item => item.id === currentFilterId)
                    } else if (loadDefault) {
                        let defaultFilter = this.savedFiltersList.find(item => item.is_default)

                        if (defaultFilter) {
                            this.updateDefault = false
                            this.savedFilter = defaultFilter
                        }
                    } else if (this.savedFilter) {
                        let saved = this.savedFiltersList.find(item => item.id === this.savedFilter.id);

                        if (saved) {
                            this.savedFilter = saved
                        }
                        else {
                            this.resetFilters()
                        }
                    }

                    this.showPresets = true
                }

                this.filtersSet = true
            })
        },

        savedFiltersChanged() {
            this.getSavedFilters()
        },

        savedFilterCreated(filterId) {
            this.getSavedFilters(filterId)
        },

        openSaveFilter() {
            this.$refs.saveFilter.openEdit()
        },

        resetFilters() {
            this.watchFilterChange = false
            this.filters.period = this.constants.commonFilterPeriods.find(item => item.value === defaultPeriod)

            this.filters.userType = this.constants.usersTypes.find(item => item.value === 'all')
            this.filters.trackers = [];
            this.filters.tags = [];
            this.filters.customFields = [];

            this.savedFilter = null
            this.showSaveFilter = false

            savedFiltersService.updateDefault(null)
            this.setFilters();

            this.$nextTick(() => {
                this.watchFilterChange = true
                this.showPresets = true
            })

        },

        customizeDashboard() {
            this.$refs.customizeDashboard.open()
        },

        openManageSavedFilters() {
            this.$refs.saveFilter.openManage()
        },

        applySavedFilter() {
            this.showSaveFilter = false
            this.watchFilterChange = false

            let filter = JSON.parse(this.savedFilter.filter)

            this.filters.period = this.constants.commonFilterPeriods.find(item => item.value === filter.period)
            this.filters.userType = this.constants.usersTypes.find(item => item.value === filter.userType)

            if ('trackers' in filter) {
                this.filters.trackers = this.trackersList.filter(item => filter.trackers.includes(item.id))
            } else {
                this.filters.trackers = [];
            }

            if ('tags' in filter) {
                this.filters.tags = this.tagsList.filter(item => filter.tags.includes(item.id))
            } else {
                this.filters.tags = [];
            }

            if ('fields' in filter) {
                this.filters.customFields = filter.fields;
            } else {
                this.filters.customFields = [];
            }

            if (this.updateDefault) {
                savedFiltersService.updateDefault(this.savedFilter.id)
            } else {
                this.updateDefault = true
            }

            this.setFilters();

            this.$nextTick(() => {
                this.watchFilterChange = true
            })
        },

        getAnalystHours() {
            analyst.getAnalystHoursLeft().then(response => {
                this.analystHours.hasAnalyst = response.data.has_analyst

                if (this.analystHours.hasAnalyst) {
                    this.analystHours.left = response.data.hours_plan
                    this.analystHours.percents = response.data.percent_left
                    this.analystHours.plan = response.data.plan_name
                    this.analystHours.loaded = true
                }
            })
        },

        addAnalystHours() {
            this.$refs.addAnalystHours.open()
        },

        loadAfterTasks() {
            this.updateAll()
            this.getAnalystHours();
            this.$store.commit('setCanLoadGlobalComponents', true);
        }
    }
}
</script>

<style scoped>

</style>
