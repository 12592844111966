<template>
    <div>
        <Header v-if="$store.getters.isEcosystem"></Header>
        <div v-if="$store.state.user.permissions.indexOf('tracker-template-manage') >= 0">
            <div v-if="$store.getters.isEnterprise" class="topbar topbar--full pt-4">
                <router-link to="/settings" class="link-back">All Settings</router-link>
                <h2 class="heading1 mb-0">Trackers</h2>
            </div>

            <div class="admin-page">
                <div class="tabs admin-page__tabs">
                    <b-nav tabs>
                        <li class="nav-item">
                            <a @click="currentTab = 'pipelines'" class="nav-link"
                               :class="{active: currentTab === 'pipelines'}">
                                Funnels
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click="currentTab = 'templates'" class="nav-link"
                               :class="{active: currentTab === 'templates'}">
                                Templates
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click="currentTab = 'submitter'" class="nav-link"
                               :class="{active: currentTab === 'submitter'}">
                                Details Fields
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click="currentTab = 'reviewer'" class="nav-link"
                               :class="{active: currentTab === 'reviewer'}">
                                Reviewer Fields
                            </a>
                        </li>
                        <li v-if="!$store.getters.isEcosystem" class="nav-item">
                            <a v-if="$store.state.accountType === 'premium'" @click="currentTab = 'questions'"
                               class="nav-link" :class="{active: currentTab === 'questions'}">
                                Startup Questions
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click="currentTab = 'diligence'" class="nav-link"
                               :class="{active: currentTab === 'diligence'}">
                                Startup Diligence
                            </a>
                        </li>
                        <li class="nav-item">
                            <a @click="currentTab = 'POC_preset'" class="nav-link"
                               :class="{active: currentTab === 'POC_preset'}">
                                POC Statuses
                            </a>
                        </li>
                    </b-nav>
                </div>

                <div class="admin-page__content main-inner">
                    <template>
                        <div v-show="currentTab === 'templates'">
                            <TemplatesList></TemplatesList>
                        </div>

                        <div v-show="currentTab === 'submitter'">
                            <FieldsList
                                type="tracker_submit"
                                v-on:action="action"
                            />
                        </div>

                        <div v-show="currentTab === 'reviewer'">
                            <FieldsList
                                type="tracker_review"
                                v-on:action="action"
                            />
                        </div>

                        <div v-if="$store.state.accountType === 'premium'" v-show="currentTab === 'questions'">
                            <FieldsList
                                type="tracker_question"
                                v-on:action="action"
                            />
                        </div>

                        <div v-show="currentTab === 'diligence'">
                            <FieldsList
                                type="tracker_diligence"
                                v-on:action="action"
                            />
                        </div>

                        <div v-show="currentTab === 'pipelines'">
                            <FieldsList
                                type="pipeline"
                                fieldType="pipeline"
                                v-on:action="action"
                            />
                        </div>

                        <div v-show="currentTab === 'POC_preset'">
                            <FieldsList
                                type="POC_preset"
                                fieldType="POC_preset"
                                v-on:action="action"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <TrackersPopupList
            ref="trackersPopupList"
        />
    </div>
</template>

<script>
import FieldsList from "@/components/trackers/FieldsList";
import TemplatesList from "@/components/trackers/TemplatesList";
import Header from "@/views/ecosystem/settings/Header.vue";
import customFields from "@/services/custom-fields.service";
import TrackersPopupList from "@/components/trackers/TrackersPopupList.vue";

export default {
    components: {
        TrackersPopupList,
        Header,
        FieldsList,
        TemplatesList,
    },

    data() {
        return {
            currentTab: "pipelines",
        };
    },

    methods: {
        action(params) {
            if (params.action === 'show-trackers') {
                customFields.getRelatedTrackers(params.rowId).then(response => {
                    this.$refs.trackersPopupList.open(response.data)
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
