import axios from '@/axios';

const RESOURCE_NAME = '/exports';

export default {
    exportCompanies(searchType, company_ids, columns) {
        return axios.post(`${RESOURCE_NAME}/export-companies`, {search_type: searchType, company_ids: company_ids, columns: columns})
    },

    exportCompaniesByQuery(searchType, query, params, sort, columns) {
        return axios.post(`${RESOURCE_NAME}/export-companies`, {search_type: searchType, query: query, params: params, sort: sort, columns: columns})
    },


    exportCompaniesFromTracker(trackerId, companies_ids, columns,searchType) {
        return axios.post(`${RESOURCE_NAME}/export-companies?tracker_id=${trackerId}`, {search_type: searchType, company_ids: companies_ids, columns: columns})
    },

    exportAllCompaniesFromTracker(trackerId, columns, searchType, filters) {
        return axios.post(`${RESOURCE_NAME}/export-companies?tracker_id=${trackerId}`, {search_type: searchType, columns, filters})
    },

    exportStartupsFundingsFromTracker(trackerId, startupsIds) {
        return axios.post(`${RESOURCE_NAME}/export-startups-fundings?tracker_id=${trackerId}`, {startup_ids: startupsIds})
    },

    exportAllStartupsFundingsFromTracker(trackerId, filters) {
        return axios.post(`${RESOURCE_NAME}/export-startups-fundings?tracker_id=${trackerId}`, {filters})
    },

    exportCompaniesContacts(companyType, companiesIds) {
        return axios.post(`${RESOURCE_NAME}/export-companies-contacts`, {companyType, companiesIds})
    },

    exportAllCompaniesContactsFromTracker(trackerId, filters) {
        return axios.post(`${RESOURCE_NAME}/export-companies-contacts?tracker_id=${trackerId}`, {filters})
    },

    generateStartupsPdf(startups_ids, settings, filters = []) {
        return axios.post(`${RESOURCE_NAME}/generate-startups-pdf`,
            {
                startups_ids,
                filters,
                settings
            }, {
                responseType: 'blob',
            }
        )
    },

    generateStartupsPpt(startups_ids, filters = []) {
        return axios.post(`${RESOURCE_NAME}/generate-startups-ppt`,
            {
                startups_ids,
                filters,
            }, {
                responseType: 'blob',
            }
        )
    },

    generateStartupsPptAdvanced(startups_ids, filters = []) {
        return axios.post(`${RESOURCE_NAME}/generate-startups-ppt-advanced`,
            {
                startups_ids,
                filters,
            }, {
                responseType: 'blob',
            }
        )
    },

    showStartupPdf(startupId) {
        return axios.get(`${RESOURCE_NAME}/generate-startup-pdf?startup_id=${startupId}&file=1`)
    },
}
