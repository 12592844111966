<template>
    <div>
        <b-modal id="modal-similar-startups-ai" modal-class="modal-w-xxl" :hide-footer="!existedStartups.length" title="Similar startups">
            <div v-if="loading || notExistedStartups.length" class="loader-startups pb-3">Loading Startups...</div>

            <table v-if="existedStartups.length" class="main-table">
                <tr>
                    <th></th>
                    <th>Company</th>
                    <th>Categories</th>
                </tr>

                <tbody>

                <tr v-for="(startup) in existedStartups" :key="startup.id">
                    <td class="main-table__check">
                        <div class="input-checkbox">
                            <input :id="'check-' + startup.id" type="checkbox" v-model="startup.checked">
                            <label :for="'check-' + startup.id"></label>
                        </div>
                    </td>

                    <td>
                        <div class="main-table__data">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(startup.logo)" alt="">
                            </div>

                            <div class="pr-3">
                                <h5 class="heading5">
                                    <a :href="'/startups/' + startup.id" target="_blank">{{startup.name}}</a>
                                </h5>
                                <div>
                                    <span class="line-clamp">{{startup.description}}</span>
                                </div>
                            </div>

                            <AddToTracker
                                v-if="$store.getters.isEnterprise"
                                :startupId="startup.id"
                                :trackersList="trackersList"
                                :trackersCount="startup.trackers_count"
                                v-on="$listeners"
                            />
                        </div>
                    </td>

                    <td><span class="line-clamp">{{startup.categories}}</span></td>
                </tr>
                </tbody>
            </table>

            <div v-if="!loading && !startups.length">
                <div class="p-4 text-center" >
                    <p class="no-similar">Could not find similar startups</p>
                </div>
            </div>

            <template #modal-footer>
                <ul v-if="existedStartups.length" class="modal-similar-startups__footer">
                    <li>{{selectedStartups.length}} startups selected</li>
                    <li>
                        <b-button variant="primary" size="md" @click="selectAll" class="button-select-all">
                            Select All
                        </b-button>
                    </li>
                    <li>
                        <b-button variant="primary" size="md" @click="deSelectAll" class="button-deselect"
                                  :disabled="!selectedStartups.length">
                            Deselect All
                        </b-button>
                    </li>
                    <li>
                        <b-button variant="primary" size="md" class="button-track"
                                  @click="openAddToTrackers"
                                  :disabled="!selectedStartups.length">
                            Add To Tracker
                        </b-button>
                    </li>
                </ul>

                <AddToTrackers
                    ref="addSimilarStartupsToTrackers"
                    modalId="add-similar-startups-to-trackers-popup"
                    :trackers-list="trackersList"
                    @trackersSelected="addToTrackers"
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
import startupsService from "@/services/startups.service";
import {mapGetters} from "vuex";
import AddToTracker from "@/components/startups/AddToTracker.vue";
import trackersCompanies from "@/services/trackers-companies.service";
import AddToTrackers from "@/components/manage/SelectTrackers.vue";

export default {
    components: {AddToTrackers, AddToTracker},

    props: {
        type: String
    },

    data() {
        return {
            id: 0,
            startups: [],
            loading: false,
            error: null,
            checkStartupsTimer: null,
        }
    },

    computed: {
        ...mapGetters(["trackersList"]),

        existedStartups() {
            return this.startups.filter(item => item.synced)
        },

        notExistedStartups() {
            return this.startups.filter(item => !item.synced)
        },

        selectedStartups() {
            return this.startups.filter(item => item.checked).map(item => item.id)
        }
    },

    methods: {
        open(id) {
            this.startups = [];
            this.loading = true;
            this.id = id;
            this.error = null;
            this.$bvModal.show('modal-similar-startups-ai');

            startupsService.getSimilarStartupsWithAI(this.id, this.type).then(response => {
                if (response.data.length) {
                    this.startups = response.data.map(item => ({
                        ...item,
                        checked: false,
                    }))

                    if (this.notExistedStartups.length) {
                        this.notExistedStartups.forEach(startup => {
                            startupsService.syncStartupFromAi(startup.id)
                        })

                        this.checkStartupsTimer = setInterval(() => {
                            this.checkStartupsSynced();
                        }, 5000)
                    }
                }
            }).finally(() => {
                this.loading = false;
            })
        },

        checkStartupsSynced() {
            startupsService.checkStartupsSynced(this.notExistedStartups.map(item => item.id)).then(response => {
                for (let id in response.data) {
                    if (response.data[id].synced) {
                        let index = this.startups.findIndex(item => parseInt(item.id) === parseInt(id));
                        Object.assign(this.startups[index], response.data[id])

                    } else if (response.data[id].delete) {
                        this.startups = this.startups.filter(item => parseInt(item.id) !== parseInt(id))
                    }
                }

                if (!this.notExistedStartups.length) {
                    clearInterval(this.checkStartupsTimer);
                }
            })
        },

        addToTrackers(addedTrackers) {
            let trackersIds = addedTrackers.map(item => item.id)
            let startups = this.selectedStartups

            trackersCompanies.addCompanies(trackersIds, startups).then(() => {
                this.deSelectAll()

                startupsService.getStartupsProperties(this.startups.map(item => item.id), ['linkedTrackersCount']).then(response => {
                    for (let id in response.data) {
                        let index = this.startups.findIndex(item => parseInt(item.id) === parseInt(id));
                        this.startups[index].trackers_count = response.data[id].linkedTrackersCount;
                    }
                })
            })
        },

        selectAll() {
            for (let i in this.startups) {
                this.startups[i].checked = true;
            }
        },

        deSelectAll() {
            for (let i in this.startups) {
                this.startups[i].checked = false;
            }
        },

        openAddToTrackers() {
            this.$refs.addSimilarStartupsToTrackers.open()
        },
    }
}
</script>

<style scoped>

</style>
