export default {
    ENTERPRISE: 'enterprise',
    ECOSYSTEM: 'ecosystem',
    STARTUP: 'startup',
}

export const licenseTypes = {
    'free': 'Free',
    'paid': 'Paid',
    'test': 'Test',
    'pilot': 'Pilot',
    'trial': 'Trial',
}
