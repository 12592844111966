import axios from '@/axios';

const RESOURCE_NAME = '/startups';

export default {

    getDetails(startupId, isRadarDetails = false) {
        let query = {params: {}}

        if (isRadarDetails) {
            query.params.radar = 1
        }

        return axios.get(`${RESOURCE_NAME}/${startupId}/get-details`, query)
    },

    getCommonData(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-common-data`)
    },

    getCustomData(startupId, type) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-custom-data?type=${type}`)
    },

    getCustomFieldsFeedbacks(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-custom-fields-feedbacks`)
    },

    setCustomFieldFeedback(startupId, fieldId, isHelpful) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/set-custom-field-feedback?field_id=${fieldId}`, {'is_helpful': isHelpful} )
    },

    saveCustomValue(startupId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/save-custom-value?field_id=${fieldId}`, data)
    },

    saveCustomFiles(startupId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/save-custom-files?field_id=${fieldId}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    saveNote(startupId, noteId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/save-note?note_id=${noteId}`, data)
    },

    getNoteEdit(startupId, noteId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-note-edit?note_id=${noteId}`)
    },

    getEcosystemsData(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-ecosystems-data`)
    },

    getTrackers(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-trackers`)
    },

    getCustomFieldValue(startupId, fieldId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-custom-field-value?field_id=${fieldId}`)
    },

    getTags(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-tags`)
    },

    getNews(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-news`)
    },

    getInvestors(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-investors`)
    },

    getAddedByCompany(data) {
        return axios.post(`${RESOURCE_NAME}/get-added-by-company`, data)
    },

    getActivities(startupId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/get-activities`, data)
    },

    getContacts(startupId, search = "") {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-contacts?search=${search}`)
    },

    getAvailableRecipients(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-available-recipients`)
    },

    getFundingRounds(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-funding-rounds`)
    },

    addErrorReport(startupId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/add-error-report`, data)
    },

    getList(search) {
        return axios.post(`${RESOURCE_NAME}/get-list`, {search: search})
    },

    getStartupDataForSelect(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-startup-data-for-select`)
    },

    getLastAddedStartups(trackerId = false) {
        if (trackerId) {
            return axios.get(`${RESOURCE_NAME}/get-last-added-startups?tracker_id=${trackerId}`)
        } else {
            return axios.get(`${RESOURCE_NAME}/get-last-added-startups`)
        }
    },

    addCategories(startupId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/add-categories`, data)
    },

    setLocation(startupId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/set-location`, data)
    },

    requestData(startupId, data) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/request-data`, data)
    },

    getTopStartups(category) {
        return axios.get(`${RESOURCE_NAME}/get-top-startups?category=${category}`)
    },

    getExistingInteractionTypes(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-existing-interaction-types`)
    },

    setConnectionRequestStatus(startupId, status) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/set-connection-request-status`, {status})
    },

    exportAddedStartups(filters) {
        return axios.post(`${RESOURCE_NAME}/export-added-startups`, {filters})
    },

    getSimilarStartupsWithAI(startupId, type) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-similar-startups-with-ai?type=${type}`)
    },

    syncStartupFromAi(startupId) {
        return axios.post(`${RESOURCE_NAME}/${startupId}/sync-startup-from-ai`)
    },

    checkStartupsSynced(ids) {
        return axios.get(`${RESOURCE_NAME}/check-startups-synced`, {
            params: {
                ids
            }
        })
    },

    getStartupsProperties(ids, properties) {
        return axios.get(`${RESOURCE_NAME}/get-startups-properties`, {
            params: {
                ids,
                properties
            }
        })
    },

    getValuation(startupId) {
        return axios.get(`${RESOURCE_NAME}/${startupId}/get-valuation`)
    },
}
