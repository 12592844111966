<template>
    <div>
        <b-modal v-if="$store.state.accountType != 'premium'" id="modal-need-upgrade" title="" :hide-footer="$store.state.accountType != 'invited'" size="lg">

                <template v-if="type == 'enterprises-connections'">
                    <h1 class="heading1">Upgrade to keep connecting</h1>

                    <p class="text-title" v-if="!$store.getters.canConnectToEnterprises">
                        You can't connect to enterprises on your current plan
                    </p>
        
                    <p class="text-title" v-if="$store.state.accountType == 'basic'">
                        You can only connect to one SwitchPitch client on your current plan
                    </p>
        
                </template>
        
                <template v-if="type == 'enterprises-invitations'">
                    <h1 class="heading1">Upgrade to invite more enterprises</h1>
        
                    <p class="text-title" v-if="!$store.getters.canInviteEnterprises">
                        You can't invite enterprises on your current plan
                    </p>
        
                    <p class="text-title" v-if="$store.state.accountType == 'basic'">
                        You can only invite up to 3 enterprises on your current plan
                    </p>
        
                </template>
        
                <template v-if="type == 'startups-invitations'">
                    <h1 class="heading1">Upgrade to invite more startups</h1>
        
                    <p class="text-title" v-if="!$store.getters.canInviteStartups">
                        You can't invite startups on your current plan
                    </p>
        
                    <p class="text-title" v-if="$store.state.accountType == 'basic'">
                        You can only invite up to 3 startups on your current plan
                    </p>
        
                </template>
        
                <template v-if="type == 'startups-add'">
                    <h1 class="heading1">Upgrade to add more startups</h1>
        
                    <p class="text-title" v-if="$store.state.accountType == 'invited'">
                        You can only add up to 250 startups to your portfolio
                    </p>

                </template>
        
                <template v-if="type == 'users'">
                    <h1 class="heading1">Upgrade to collaborate with more coworkers</h1>

                    <p class="text-title" v-if="$store.state.accountType == 'free'">
                        You can't add users on your current plan
                    </p>

                    <p class="text-title" v-if="$store.state.accountType == 'invited'">
                        You can only have 2 users on your current plan
                    </p>

                    <p v-if="$store.state.accountType == 'basic'">
                        You can only have 3 users on your current plan
                    </p>
                </template>
        
        
                <div class="account-page">
                    <div class="account-plan">
                        <div class="account-plan__item single-plan account-plan__item--premium">
                            
                            <div class="account-plan__item__wrapper">
                                <h3 class="heading3">Premium Access</h3>
                    
                                <ul class="account-plan__list-check">
                                    <li v-for="(item, index) in constants.ecosystemAccountTypesFeatures['premium']" :key="index" :class="{'account-plan__list-check__selected': index == activeItem}">{{item}}</li>
                                </ul>
                            </div>
                            
                            <footer class="account-plan__item__footer">
                                <p>{{constants.ecosystemAccountTypesPrices['premium']}}</p>
                
                                <button v-if="$store.state.accountType != 'premium'" @click="upgrade('premium')" class="button">Upgrade To Premium</button>
                            </footer>
                        </div>
                    </div>
                </div>
                <template #modal-footer v-if="$store.state.accountType == 'invited'">
                    <b-button variant="primary" size="lg" @click="close">Keep This Plan</b-button>
                </template>
        </b-modal>

        <b-modal v-else id="modal-need-add-costs" hide-footer title="Unable To Perform Action">
            <p class="text-title">
                Access to this feature can be purchased at an additional cost
            </p>

            <div class="buttons-wrapper pb-4">
                <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Us</a>
            </div>
        </b-modal>

    </div>
</template>

<script>

export default {
    data() {
        return {
            type: Number,
        }
    },

    computed: {
        activeItem() {
            if (this.type == 'enterprises-connections') {
                return 0
            }
            else if (this.type == 'enterprises-invitations') {
                return 1
            }
            else if (this.type == 'startups-add') {
                return 2
            }
            else if (this.type == 'users') {
                return 3
            }
            else {
                return -1
            }
        }
    },

    mounted() {
        this.$store.watch(state => {
            if (state.companyType == 'ecosystem' && state.showNeedUpgrade) {
                this.type = state.showNeedUpgrade

                if (this.$store.state.accountType == 'premium') {
                    this.$bvModal.show('modal-need-add-costs')
                }
                else {
                    this.$bvModal.show('modal-need-upgrade')
                }
            }
        })
    },

    methods: {
        close() {
            this.$bvModal.hide('modal-need-upgrade')
        },

        upgrade(type) {
            this.$bvModal.hide('modal-need-upgrade')
            this.showUpgrade(type)
        }
    }
}
</script>
