<template>
    <div class="key__filter">
        <span class="key__filter__join" v-if="isOr(query)">[ </span>
        <draggable v-model="query.rules" class="key__filter" group="g1">
            <span class="key__filter__wrapper" v-for="(item, key) in query.rules" :key="key">
                <template v-if="!isGroup(item)">
                    <span class="key__filter__tag" :class="{group__rule: typeof item.groupWords !== 'undefined' && item.groupWords.length > 0, negative: item.negative}" >
                        <equation-rule
                            :key="key"
                            :rule="item"
                            v-on:delete-rule="deleteRule"
                            v-on:invert-rule="invertRule"
                            v-on:edit-group="editGroup"
                        >
                        </equation-rule>
                    </span>

                    <span class="key__filter__tag key__filter__connection"
                          @click="toggleCondition(item, query, false)"
                          v-show="showCond(key, query.rules) && query.rules.length">
                                {{ getCondAlias(query) }}
                    </span>
                </template>
                <template v-else>
                    <equation-bar
                        :query="item"
                        :group="true"
                        v-on:rule-deleted="deleteRule"
                        v-on:invert-rule="invertRule"
                        v-on:toggle-condition="toggleGroupCondition"
                        v-on:edit-group="editGroup"
                    ></equation-bar>
                    <span class="key__filter__tag key__filter__connection"
                          @click="toggleCondition(item, query, true)"
                          v-show="showCond(key, query.rules)">
                                {{ getCondAlias(query) }}
                    </span>
                </template>
            </span>
        </draggable>
        <span class="key__filter__join" v-if="isOr(query)">]</span>

    </div>

</template>

<script>
    import EquationRule from './EquationRule.vue'
    import draggable from "vuedraggable";

    export default {
        name: 'equationBar',
        props : {
            query: Object,
            group: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            'equation-rule' : EquationRule,
            draggable,
        },
        data: function() {
            return {
                'oldIndex': null,
                'newIndex': null,
            };
        },
        methods: {
            /*onStart: function (e) {
                this.oldIndex = $(e.item).index('.drag-span') - 1;
            },
            onEnd: function (e) {
                this.newIndex = $(e.item).index('.drag-span');
                if (this.oldIndex != this.newIndex) {
                    this.reorderRules();
                }
            },*/
            isGroup : function(query) {
                return (typeof query.condition !== 'undefined') ? true : false;
            },
            isAnd: function(query) {
                if (!this.isGroup(query)) {
                    return true;
                }
                else {
                    return query.condition === 'And';
                }
            },
            isOr: function(query) {
                if (!this.isGroup(query)) return false;
                return query.condition === 'OR';
            },
            getCond: function(query) {
                return this.isAnd(query) ? 'AND' : 'OR';
            },
            getCondAlias: function(query) {
                return this.isAnd(query) ? 'And' : 'Or';
            },
            showCond: function (index, rules) {
                if (index === rules.length - 1) {
                    return false;
                }
                else {
                    return true;
                }
            },
            showCondOld: function(item, query) {
                if (this.isOr(query))  {
                    var lastIndex = query.rules.length - 1;
                    if (query.rules[lastIndex].id == item.id) {
                        return false;
                    }
                }

                return true;
            },
            /**** Event Listeners ****/
            toggleCondition: function(rule, query, inGroup) {
                let oldCond = this.getCond(query)
                this.$emit('toggle-condition', rule, inGroup, oldCond)
            },

            toggleGroupCondition: function (rule, inGroup, oldCond) {
                this.$emit('toggle-condition', rule, inGroup, oldCond)
            },

            deleteRule: function(rule) {
                this.$emit('rule-deleted', rule);
            },

            invertRule: function(rule) {
                if (rule.name === 'trackers' && rule.value[0] === 'all') {
                    return
                }

                this.$emit('invert-rule', rule);
            },

            editGroup: function(rule) {
                this.$emit('edit-group', rule);
            },
        }
    }
</script>
