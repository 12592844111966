<template>
    <div>
        <div class="topbar topbar--search">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <div class="form-search-primary-wrapper">
                        <template v-if="customView">
                            <input
                                v-model="searchString"
                                class="form-search-primary"
                                placeholder="Search by Name or Description"
                                v-debounce:500ms.lock="getList">
                        </template>

                        <template v-else>
                            <Autocompleter
                                ref="keywordAutocompleter"
                                v-model="currentKeyword"
                                placeHolder="Add a Keyword"
                                inputClass="form-search-primary"
                                :update-function= "searchService.suggest"
                                :clearOnSubmit = true
                                v-on:submitted="keywordAutocompleteSubmitted"
                            >
                            </Autocompleter>

                            <div class="topbar__hint">
                                <b-dropdown id="dropdown-1" text="" class="icons-list dropdown-small">
                                    <b-dropdown-item class="icons-list__quote" @click="applySearchOperator('exact-match', currentKeyword);">
                                        Use quotes for an exact match
                                    </b-dropdown-item>

                                    <b-dropdown-item class="icons-list__startup" @click="applySearchOperator('name', currentKeyword);">
                                        Type “Name:” to search for a<template v-if="searchType === searchTypes.ECOSYSTEMS || searchType === searchTypes.ENTERPRISES">n</template>
                                        <br/>
                                        <template v-if="searchType === searchTypes.ECOSYSTEMS">ecosystem’s</template>
                                        <template v-else-if="searchType === searchTypes.ENTERPRISES">enterprise’s</template>
                                        <template v-else>startup’s</template> name
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="false" class="icons-list__lightbulb">
                                        Learn how to use search
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </template>
                        
                        <header class="topbar__key__header">
                            Showing
                            <select v-if="totalRowsCount >= 25" class="topbar__key__select" v-model="pageLength">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>
                            <template v-else>{{totalRowsCount}}</template>
    
                            of {{stringHelper.formatNumber(totalRowsCount)}}
                        </header>
                    </div>

                    <footer class="topbar__key__footer">
                        <template v-if="!customView">
                            <span class="topbar__key__label">Filtered by:</span>

                            <equation-bar
                                :query="query"
                                v-on:rule-deleted="deleteRule"
                                v-on:delete-all-rules="deleteAllRules"
                                v-on:invert-rule="invertRule"
                                v-on:edit-group="editGroup"
                                v-on:toggle-condition="toggleCondition"
                            >
                            </equation-bar>
                        </template>

                        <span v-else class="topbar__key__label align-self-end">Displaying only startups added by your company</span>

                        <a v-if="query.rules.length && !$store.getters.isFreeEnterprise" @click="deleteAllRules" class="key__filter__clear">Clear All</a>

                        <ul class="topbar__side actions-list" style="top: 5px;">
                            <template v-if="!customView">
                                <li v-if="showRadarSwitcher"><a class="topbar__side__radar" @click="changeView('radar')">View As Radar</a></li>
                                <li v-else-if="isRadarView"><a class="topbar__side__list" @click="changeView('list')">View As List</a></li>

                                <li v-if="canUpdateSavedSearch"><a class="topbar__side__save" @click="updateSavedSearch">Update Saved Search</a></li>
                                <li v-if="query.rules.length"><a class="topbar__side__save" @click="openSaveSearch">Save Search</a></li>
                            </template>

                            <li v-if="searchType === searchTypes.STARTUPS && selectedRows.length" class="actions-list__add"><a @click="openAddToTrackers">Add To Trackers</a></li>
                            <li>
                                <div>
                                    <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">

                                        <b-dropdown-item class="action action--edit" @click="openManageColumns">
                                            Edit Columns
                                        </b-dropdown-item>

                                        <template v-if="selectedRows.length">

                                            <b-dropdown-item v-if="$store.getters.isEnterprise" class="action" @click="openAddTags">
                                                <font-awesome-icon :icon="['fa', 'tags']" />
                                                Add Tags
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="$store.getters.isEnterprise" class="action" @click="openRemoveTags">
                                                <font-awesome-icon :icon="['fa', 'trash']" />
                                                Remove Tags
                                            </b-dropdown-item>

                                            <template v-if="$store.state.accountType !=='trial' && this.$store.state.realAccountType !== 'trial'">
                                                <b-dropdown-item class="action" @click="exportCSV">
                                                    <font-awesome-icon :icon="['fa', 'file-csv']" />
                                                    Export CSV
                                                </b-dropdown-item>

                                                <template v-if="searchType === searchTypes.STARTUPS && !selectedAllResults && selectedRows.length < 125">
                                                    <b-dropdown-item class="action action--pdf" @click="openExportPdf">
                                                        Export PDF
                                                    </b-dropdown-item>

                                                    <b-dropdown-item class="action action--ppt" @click="exportPPT">
                                                        Export PPT
                                                    </b-dropdown-item>

                                                    <b-dropdown-item v-if="$store.state.realAccountType === 'test'" class="action action--ppt" @click="exportPPTAdvanced">
                                                        Export PPT (Advanced)
                                                    </b-dropdown-item>
                                                </template>
                                            </template>

                                            <b-dropdown-item v-if="false" class="action" v-b-modal.modal-ExportContacts>
                                                <font-awesome-icon :icon="['fa', 'address-book']" />
                                                Export Contacts
                                            </b-dropdown-item>

                                            <b-dropdown-item class="action" @click="connect" v-if="searchType === searchTypes.ENTERPRISES && ['premium', 'standard','trial'].includes($store.state.user.account_type)">
                                                <font-awesome-icon :icon="['fa', 'plus']" />
                                                Request Invite & Connect
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </div>
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>

        <div>
            <main class="main main--scrolled" :class="{'main--collapsed': leftCollapsed}">
                <aside v-if="!customView" class="aside">
                    <div class="aside__inner">
                        <b-tabs class="tabs-primary" content-class="mt-3" v-model="asideTabIndex">
                            <b-tab>
                                <template #title>
                                    Filters

                                    <a v-if="isListView" class="aside__collapse" @click="leftCollapsed = !leftCollapsed"></a>
                                </template>

                                <div class="aside-support" v-if="!leftCollapsed">
                                    <template v-if="searchType === searchTypes.STARTUPS && $store.getters.isEnterprise">
                                        <a class="aside-support__help" @click="showHelpModal">Help</a>
                                        <a class="aside-support__ask" @click="showAnalystMessageForm">Ask Analyst</a>
                                    </template>

                                    <template v-if="$store.getters.isStartup">
                                        <a class="aside-support__get" @click="showContactUsMessageForm">Get Introductions</a>
                                    </template>
                                </div>

                                <div class="tabs-primary__inner">
                                    <ul class="filters__list">
                                        <li v-if="searchType === searchTypes.ECOSYSTEMS">
                                            <a class="filters__list__title" v-b-toggle.filter-ecosystemTypes>
                                                Type
                                            </a>
                                            <b-collapse class="filters__list__content" id="filter-ecosystemTypes">
                                                <ul class="filters__list__choose mb-3">
                                                    <li class="input-checkbox mr-1" v-for="option in filtersOptions['ecosystemTypes']" :key="option.id">
                                                        <input v-model="filters['ecosystemTypes']" :value="option" :id="'filter-ecosystemTypes-' + option.value" type="checkbox">
                                                        <label :for="'filter-ecosystemTypes-' + option.value">{{ option.name }}</label>
                                                    </li>
                                                </ul>
                                            </b-collapse>

                                            <a class="filters__list__title" v-b-toggle.filter-favorite>
                                                Favorite
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-favorite">
                                                <div class="input-checkbox">
                                                    <input type="checkbox" id="favorited-input" v-model="favorited">
                                                    <label for="favorited-input">Favorited</label>
                                                </div>
                                            </b-collapse>
                                        </li>

                                        <li>
                                            <a class="filters__list__title" v-b-toggle.filter-category>
                                                Category
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-category">
                                                <multiselect
                                                    v-model="filters.categories"
                                                    :multiple="true"
                                                    placeholder="Select categories"
                                                    :options="filtersOptions.categories"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS">
                                            <a class="filters__list__title" v-b-toggle.filter-fundingSeries @click="getTopInvestorsList">
                                                Funding
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-fundingSeries">
                                                <ul class="filters__list__choose mb-3">
                                                    <li class="input-checkbox mr-1" v-for="option in filtersOptions['fundingSeries']" :key="option.id">
                                                        <input v-model="filters['fundingSeries']" :value="option" :id="'filter-fundingSeries-' + option.value" type="checkbox">
                                                        <label :for="'filter-fundingSeries-' + option.value">{{ option.name }}</label>
                                                    </li>
                                                </ul>

                                                <h6 class="heading6 heading6--secondary">Last funding date:</h6>

                                                <a class="calendar-link" style="margin-bottom: 10px" v-if="!showCalendar.lastFundingDate" @click="showCalendar.lastFundingDate = true">Select Date</a>
                                                <date-picker style="margin-bottom: 10px" v-if="showCalendar.lastFundingDate" v-model="filters.lastFundingDate" type="year" range :default-value="[new Date(Date.now()-1000*60*60*24*365*20), new Date(Date.now()-1000*60*60*24*365*10)]"></date-picker>

                                                <multiselect
                                                    v-model="filters.fundingRange"
                                                    placeholder="Select funding range"
                                                    :multiple="true"
                                                    :options="filtersOptions.fundingRange"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>

                                                <h6 class="heading6 heading6--secondary mt-3">Investors:</h6>
                                                <multiselect
                                                    v-model="filters.investors"
                                                    :multiple="true"
                                                    placeholder="Select investors"
                                                    :options="filtersOptions.investors"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    @search-change="getInvestorsList"
                                                    :loading="loadingInvestors"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li>
                                            <a class="filters__list__title" v-b-toggle.filter-location>
                                                Location
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-location">
                                                <multiselect
                                                    v-model="filters.locations"
                                                    :multiple="true"
                                                    placeholder="Select locations"
                                                    :options="filtersOptions.locations"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    @search-change="updateStartupLocationsList"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS">
                                            <a class="filters__list__title" v-b-toggle.filter-score>
                                                IRL Score
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-score">
                                                <header class="filter-score__header">
                                                    {{irlScoreFilterValue[0]}} - {{irlScoreFilterValue[1]}}
                                                </header>
                                                <vue-slider
                                                    ref="scoreSlider"
                                                    v-model="irlScoreFilterValue"
                                                    tooltip="none"
                                                    :min="1"
                                                    :max="10"
                                                    @drag-end="scoreFilterChanged"
                                                />
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS && tagsLoaded && (tagsList.length || $store.state.user.permissions.includes('tags-manage'))">
                                            <a class="filters__list__title" v-b-toggle.filter-tags>
                                                Tags
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-tags">
                                                <multiselect
                                                    v-if="tagsList.length"
                                                    v-model="filters.tags"
                                                    :multiple="true"
                                                    placeholder="Select tags"
                                                    :options="filtersOptions.tags"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>

                                                <router-link v-else-if="$store.state.user.permissions.includes('tags-manage')" to="/settings/my-tags" class="filters__list__link">Create Tag</router-link>
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS">
                                            <a class="filters__list__title" v-b-toggle.filter-custom-fields>
                                                Custom Fields
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-custom-fields">
                                                <a class="filters__list__link" :class="customFieldsRules.length ? 'filters__custom_selected' : 'filters__custom'" @click="openCustomDataFilter">{{ filterByCustomFieldsLabel }}</a>
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS">
                                            <a class="filters__list__title" v-b-toggle.filter-clients>
                                                Competitive Intelligence
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-clients">
                                                <multiselect
                                                    v-model="filters.clients"
                                                    :multiple="true"
                                                    placeholder="Select clients"
                                                    :options="filtersOptions.clients"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    @search-change="updateClientsList"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>

                                                <br>
                                                <multiselect
                                                    v-model="filters.clientsCategories"
                                                    :multiple="true"
                                                    placeholder="Select industries"
                                                    :options="filtersOptions.categories"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS && $store.getters.isEnterprise">
                                            <a class="filters__list__title" v-b-toggle.filter-ecosystem>
                                                Ecosystems
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-ecosystem">
                                                <multiselect
                                                    :disabled="$store.getters.isFreeEnterprise"
                                                    v-model="filters.ecosystems"
                                                    :multiple="true"
                                                    placeholder="Select ecosystems"
                                                    :options="filtersOptions.ecosystems"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    @search-change="updateEcosystemsList"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li v-if="searchType === searchTypes.STARTUPS && $store.getters.isEnterprise">
                                            <a class="filters__list__title" v-b-toggle.filter-trackers>
                                                Trackers
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-trackers">
                                                <multiselect
                                                    :value="filters.trackers"
                                                    :multiple="true"
                                                    placeholder="Select trackers"
                                                    :options="filtersOptions.trackers"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                    @select="trackerFilterSelected"
                                                    @remove="removeSelectedFilter"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li>
                                            <a class="filters__list__title" v-b-toggle.filter-saved-searches>
                                                Saved Searches
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-saved-searches">
                                                <multiselect
                                                    v-model="savedSearch"
                                                    :options="savedSearches"
                                                    placeholder="Select saved search"
                                                    select-label=""
                                                    deselect-label=""
                                                    group-values="items"
                                                    group-label="group"
                                                    :group-select="false"
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>

                                                <a v-if="savedSearches.length" @click="openManageSavedSearches" class="filters__list__link d-block text-right">Manage Saved Searches</a>
                                            </b-collapse>
                                        </li>

                                        <li v-if="showSearchSimilarStartups && $store.getters.isEnterprise">
                                            <a class="filters__list__title" v-b-toggle.filter-similar-tracker>
                                                Search By Similar Startups
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-similar-tracker">
                                                <multiselect
                                                    v-model="filters.similarTracker"
                                                    placeholder="Select tracker"
                                                    :options="filtersOptions.trackers"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>
                                            </b-collapse>
                                        </li>

                                        <li>
                                            <a class="filters__list__title" v-b-toggle.filter-more>
                                                More
                                            </a>

                                            <b-collapse class="filters__list__content" id="filter-more">
                                                <template v-if="searchType === searchTypes.STARTUPS">
                                                    <multiselect
                                                        v-model="filters.revenueRange"
                                                        placeholder="Select revenue range"
                                                        :multiple="true"
                                                        :options="filtersOptions.revenueRange"
                                                        select-label=""
                                                        deselect-label=""
                                                        label="name"
                                                        track-by="name"
                                                        class="multiselect-mid pl-2"
                                                    >
                                                    </multiselect>
                                                    <br>
                                                </template>

                                                <multiselect
                                                    v-model="filters.employeeCount"
                                                    placeholder="Select employee count"
                                                    :multiple="true"
                                                    :options="employeeCount"
                                                    select-label=""
                                                    deselect-label=""
                                                    label="name"
                                                    track-by="name"
                                                    class="multiselect-mid pl-2"
                                                >
                                                </multiselect>

                                                <br/>

                                                <template v-if="searchType !== searchTypes.ENTERPRISES">
                                                    <h6 class="heading6 heading6--secondary">Date founded:</h6>

                                                    <a class="calendar-link" v-if="!showCalendar.foundedYear" @click="showCalendar.foundedYear = true">Select Date</a>
                                                    <date-picker v-if="showCalendar.foundedYear" v-model="filters.foundedYear" type="year" range :default-value="[new Date(Date.now()-1000*60*60*24*365*20), new Date(Date.now()-1000*60*60*24*365*10)]"></date-picker>
                                                </template>

                                                <template v-if="searchType === searchTypes.STARTUPS">
                                                    <div class="input-checkbox mt-3">
                                                        <input type="checkbox" id="include-input" v-model="hasInteractions">
                                                        <label for="include-input">Has Interactions</label>
                                                    </div>

                                                    <template v-if="hasInteractions">
                                                        <a class="calendar-link" @click="() => {showCalendar.lastInteraction = true}">Select Date</a>
                                                        <date-picker v-if="showCalendar.lastInteraction" v-model="filters.lastInteraction" format = "MM/DD/YYYY" range/>
                                                    </template>
                                                </template>

                                                <template v-if="searchType === searchTypes.STARTUPS">
                                                    <div class="input-checkbox mt-3">
                                                        <input type="checkbox" id="include-inactive-input" v-model="includeInactive">
                                                        <label for="include-inactive-input">Show Inactive</label>
                                                    </div>

                                                    <div class="input-checkbox mt-3">
                                                        <input type="checkbox" id="include-acquired-input" v-model="includeAcquired">
                                                        <label for="include-acquired-input">Show Acquired</label>
                                                    </div>
                                                </template>

                                                <template v-if="searchType === searchTypes.STARTUPS">
                                                    <div class="input-checkbox mt-3">
                                                        <input type="checkbox" id="not-on-crunchbase" v-model="notOnCrunchbase">
                                                        <label for="not-on-crunchbase">Not on Crunchbase</label>
                                                    </div>
                                                </template>

                                                <template v-if="false && searchType === searchTypes.STARTUPS">
                                                    <div class="input-checkbox mt-3">
                                                        <input type="checkbox" id="disable-default-sorting" v-model="disableDefaultSorting">
                                                        <label for="disable-default-sorting">Disable Default Sorting</label>
                                                    </div>
                                                </template>
                                            </b-collapse>
                                        </li>
                                    </ul>
                                </div>
                            </b-tab>

                            <b-tab v-if="searchType === searchTypes.STARTUPS" :title-item-class="radarSettingsTabClass">
                                <template #title>
                                    Radar Settings
                                </template>
                                <div class="tabs-primary__inner">
                                    <RadarSettings
                                        ref="radarSettings"
                                    />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </aside>

                <div class="content">
                    <div class="content__inner" style="position: relative">
                        <pulse-loader :loading="showSpinner" :color="constants.spinnerColor"></pulse-loader>

                        <template v-if="startups.length">
                            <Grid
                                v-if="isListView"
                                :columns="columns"
                                :has-checkbox-column="true"
                                :addCellData="addCellData"
                                :data="startups"
                                :page-length="parseInt(pageLength)"
                                :total-rows-count="totalRowsCount"
                                :sort="sort"
                                :currentPage="currentPage"
                                v-on:changePage="changePage"
                                v-on:changeSort="changeSort"
                                v-on:manageColumns="openManageColumns"
                                v-on:selectedRowsChanged="changeSelectedRows"
                                v-on:selectAllResults="selectAllResults"
                                v-on:action="gridAction"/>

                            <Radar v-else-if="isRadarView"
                                :query="query"
                                :includeHidden="includeHidden"
                                :includeInactive="includeInactive"
                                :includeAcquired="includeAcquired"
                                :hasInteractions="hasInteractions"
                                :notOnCrunchbase="notOnCrunchbase"
                                :disableDefaultSorting="disableDefaultSorting"
                            />

                            <div v-if="notShownCount" class="upgrade-membership">
                                <h5 class="upgrade-membership__title">{{notShownCount}} more companies match this search</h5>
                                <p>Upgrade your SwitchPitch membership to see them.</p>

                                <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
                            </div>
                        </template>

                        <div v-if="loaded && !startups.length" class="not-found">
                            <template v-if="searchType === searchTypes.STARTUPS">
                                <h2 class="heading2 heading2--startups">No Results</h2>

                                <template v-if="!customView && $store.getters.isEnterprise && !$store.getters.isFreeEnterprise">
                                    <p>If you need help finding good startups, try messaging an ecosystem on the platform. They’d be happy to share their startups with you.</p>

                                    <a :href="'/ecosystems'" class="btn btn-primary btn-lg">View Ecosystems</a>
                                </template>
                            </template>

                            <template v-if="searchType === searchTypes.ECOSYSTEMS">
                                <h2 class="heading2 heading2--ecosystems">No ecosystems</h2>

                                <p>Try removing or changing some of your keywords</p>

                            </template>

                            <template v-if="searchType === searchTypes.ENTERPRISES">
                                <h4 class="heading4">No enterprises</h4>

                                <p>Try removing or changing some of your keywords</p>
                            </template>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        <ManageColumns
            ref="manageColumns"
            :tableType="tableColumnsType"
            v-on:changeColumns="getColumns"
        >
        </ManageColumns>

        <CustomDataFilterModal
            v-if="!$store.getters.isStartup"
            :filters-list="customFieldsList"
            :for-search="true"
            ref="customDataFilter"
            v-on:updateCustomDataFilter="updateCustomDataFilter"
        />

        <SaveSearch
            ref="saveSearch"
            :searchType="searchType"
            :rules="query.rules"
            :savedSearchesList="savedSearches"
            v-on:saved-search-changed="savedSearchChanged"
        >
        </SaveSearch>

        <AddTags
            ref="addTags"
            :tags-list="tagsList"
            v-on:addTags="addTags"
        >
        </AddTags>

        <RemoveTags
            ref="removeTags"
            :tags-list="tagsList"
            v-on:removeTags="removeTags"
        >
        </RemoveTags>

        <AddToTrackers
            ref="addToTrackers"
            :trackers-list="trackersList"
            v-on:trackersSelected="addToTrackers"
        >
        </AddToTrackers>

        <b-modal id="modal-ExportContacts" title="Export Contacts" size="md">

            <p>Enter the titles of the contacts you want to export</p>
            <input type="text" class="form-control form-control--mid mb-3" placeholder="Title"/>

            <ul class="rows-list">
                <li>
                    <h5 class="heading5 heading5--secondary">Brand Manager</h5>
                    <a href="#" class="link-remove ml-auto">Remove</a>
                </li>
                <li>
                    <h5 class="heading5 heading5--secondary">Brand Manager</h5>
                    <a href="#" class="link-remove ml-auto">Remove</a>
                </li>
            </ul>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="add">Export</b-button>
            </template>
        </b-modal>

        <GroupWords
            :rule="currentRule"
            ref="manageGroupWords"
            :allRelatedWords="relatedWords"
            v-on:save-group-words="saveGroupWords"
        >
        </GroupWords>

        <edit-tracker
            v-if="$store.getters.isNotEcosystemOrPremium"
            ref="editTracker"
            v-on:trackerSaved="trackerSaved"
        >
        </edit-tracker>

        <similar-startups
            type="search"
            ref="similarStartups"
        >
        </similar-startups>

        <b-modal id="modal-show-help" modal-class="modal-transparent" :hide-header="true" :hide-footer="true" size="md">
            <div class="embed-responsive embed-responsive-16by9">
                <iframe width="420" height="315" src="https://www.youtube.com/embed/qNXfEMU_SDY"></iframe>
            </div>

            <div class="modal-transparent__footer">
                <a href="https://switchpitch.document360.io/docs/startup-search" target="_blank">Learn more about how to make the most of SwitchPitch’s search capabilities on our knowledge base</a>
            </div>
        </b-modal>

        <message-modal
            id="analyst-message-modal"
            ref="analystMessageModal"
            title="Message Analyst"
            type="analyst"
        />

        <message-modal
            id="contact-us-message-modal"
            ref="contactUsMessageModal"
            title="Get Introductions"
            type="contactUs"
        />

        <b-modal id="modal-show-upgrade" modal-class="modal-show-upgrade modal-w-md" :hide-footer="true" title="Discover over 450,000 more startups">
            <!--<h5 class="modal-title">Discover over 400,000 more startups</h5>-->

            <p>Upgrade your SwitchPitch membership to find, access and manage startup relationships.</p>


            <div class="modal-footer">
                <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
            </div>
        </b-modal>

        <ExportPdf
            ref="exportPdf"
        />

        <StartChatPopup
            ref="startChat"
            contactsType="ecosystem"
        />

        <helper v-if="searchType === searchTypes.STARTUPS && $store.getters.isEnterprise"
            page="search"
        />

        <helper v-if="searchType === searchTypes.ECOSYSTEMS && $store.getters.isEnterprise"
            page="ecosystems"
        />

    </div>
</template>


<script>
import multiselect from 'vue-multiselect'
import Grid from '@/components/Grid'
import Autocompleter from '@/components/Autocompleter'
import EquationBar from '@/components/search/EquationBar.vue'
import equation from '@/components/search/equation'
import grid from '@/mixins/grid'
import scroll from '@/mixins/scroll'
import tags from '@/services/tags.service'
import trackers from '@/services/trackers.service'
import trackersCompanies from '@/services/trackers-companies.service'
import locations from '@/services/locations.service'
import savedSearches from '@/services/saved-searches.service'
import startups from '@/services/startups.service'
import ecosystems from '@/services/ecosystems.service'
import investors from '@/services/investors.service'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ManageColumns from '@/components/manage/ManageColumns.vue';
import SaveSearch from '@/components/search/SaveSearch.vue';
import AddTags from '@/components/manage/AddTags.vue';
import RemoveTags from "@/components/manage/RemoveTags";
import AddToTrackers from '@/components/manage/SelectTrackers.vue';
import SimilarStartups from '@/components/startups/SimilarStartupsWithAi.vue';
import date from "@/helpers/date";
import GroupWords from "@/components/search/GroupWords";
import editTracker from "@/components/trackers/EditTracker";
import user from "@/services/user.service";
import PulseLoader from "vue-spinner/src/MoonLoader";

import enterprises from "@/services/ecosystem/enterprises.service";
import search from "@/services/search.service";
import Vue from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import RadarSettings from "@/components/radar/RadarSettings"
import MessageModal from "@/components/MessageModal.vue";
import searchFilterOptions from "@/constants/searchFilterOptions";
import searchTypes from "@/constants/searchTypes";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import ExportPdf from "@/components/export/ExportPdf.vue";
import exportsService from "@/services/exports.service";
import startupsService from "@/services/startups.service";
import clientsService from "@/services/clients.service";
import Helper from "@/components/Helper.vue";
import accountTypes from "@/constants/accountTypes";
import CustomDataFilterModal from "@/components/search/CustomDataFilterModal.vue";

export default {
    props: {
        searchType: String,

        customView: {
            type: String,
            default: null,
        }
    },

    components: {
        CustomDataFilterModal,
        Helper,
        ExportPdf,
        MessageModal,
        RadarSettings,
        Grid,
        EquationBar,
        multiselect,
        DatePicker,
        ManageColumns,
        SaveSearch,
        AddTags,
        RemoveTags,
        AddToTrackers,
        Autocompleter,
        GroupWords,
        editTracker,
        PulseLoader,
        SimilarStartups,
        VueSlider,
        Radar: () => import("@/components/radar/Radar"),
        StartChatPopup: () => import("@/views/messages/StartChatPopup"),
    },

    mixins: [equation, grid, scroll],

    data() {
        return {
            displayType: 'list',

            startups: [],
            loaded: false,

            filtersOptions: searchFilterOptions,

            filters: {
                fundingSeries: [],
                fundingRange: [],
                revenueRange: [],
                employeeCount: [],
                lastFundingDate: null,
                foundedYear: null,
                lastInteraction: null,
                tags: [],
                categories: [],
                locations: [],
                ecosystems: [],
                trackers: [],
                investors: [],
                clients: [],
                clientsCategories: [],
                similarTracker: null,
                ecosystemTypes: [],
                irlScore: [1, 10],
            },

            irlScoreFilterValue: [1, 10],
            includeHidden: false,
            hasInteractions: false,
            includeInactive: false,
            includeAcquired: false,
            notOnCrunchbase: false,
            favorited: false,
            disableDefaultSorting: false,

            savedSearch: null,
            savedSearches: [],
            canUpdateSavedSearch: false,
            savedSearchApplied: false,
            customFieldsList: {},

            topStartupLocations: [],
            topEcosystems: [],
            topInvestors: [],
            topClients: [],
            loadingInvestors: false,

            leftCollapsed: false,
            currentKeyword: "",

            currentRule: {
                id: 0,
                value: "",
                groupWords: {},
                matchCount: {},
            },

            startupDetails: Array,
            notWatchFilters: false,

            showCalendar: {
                'lastFundingDate': true,
                'foundedYear': false,
                'lastInteraction': false,
            },

            addCellData: {
                companyType: this.searchType,
                trackersList: [],
                fromSearch: true,
            },

            relatedWords : {},
            initSavedSearch: 0,

            addStartupToTracker: null,
            notWatchQuery: false,

            showSearchSimilarStartups: false,

            asideTabIndex: 0,

            loadListStoppers: {
                companyType: true,
                columns: true,
                queryTag: true,
                querySavedSearch: true,
                queryCategory: true,
                queryEcosystem: true,
                connectedEcosystems: true,
                settings: true,
            },

            stateWatcher: null,
            notShownCount: 0,
            searchString: "",
        }
    },

    computed: {
        searchTypes() {
            return searchTypes
        },

        ...mapGetters(['trackersList','tagsList','tagsLoaded']),

        ...mapGetters({
            showSpinner: 'search/isShownSpinner',
        }),

        isNotProd() {
            return process.env.NODE_ENV !== 'production';
        },

        searchService() {
            return search;
        },

        canLoadList() {
            let canLoad = true

            for (let i in this.loadListStoppers) {
                if (this.loadListStoppers[i]) {
                    canLoad = false
                    break
                }
            }

            return canLoad
        },

        tableColumnsType() {
            if (this.searchType === searchTypes.ECOSYSTEMS) {
                return 'search-ecosystems'
            }
            else if (this.searchType === searchTypes.ENTERPRISES) {
                return 'search-enterprises'
            }
            else {
                return 'search-startups'
            }
        },

        employeeCount() {
            let employeeCount = this.filtersOptions.employeeCount

            if (this.searchType === "enterprises") {
                employeeCount.push(
                    {
                        name: 'More than 500',
                        value: {
                            'min': 501,
                        }
                    },
                )
            }

            return employeeCount
        },

        filterByCustomFieldsLabel() {
            let selectedCount = this.customFieldsRules.length
            let label = "Filter By Your Custom Fields"

            if (selectedCount) {
                label = selectedCount + (selectedCount === 1 ?  " custom field selected" : " custom fields selected")
            }

            return label
        },

        isListView() {
            return this.displayType === 'list'
        },

        isRadarView() {
            return this.displayType === 'radar'
        },

        showRadarSwitcher() {
            return this.searchType === searchTypes.STARTUPS && this.isListView
        },

        radarSettingsTabClass() {
            return !this.isRadarView ? 'd-none' : ''
        }

    },

    mounted() {
        if (this.$store.state.companyType) {
            this.loadListStoppers.companyType = false
        }

        this.getAddLists()

        this.$store.watch(state => {
            this.showSearchSimilarStartups = !this.$store.getters.isEcosystem && this.searchType !== searchTypes.ECOSYSTEMS

            // if (state.needUpdateTrackers) {
            //     this.getTrackersList()
            // }

            if (state.user.company_id === this.constants.companyPfizer && state.user.settings.search_big_companies) {
                this.includeHidden = true
            }

            if (state.user.id) {
                if (state.companyType === accountTypes.ENTERPRISE) {
                    this.includeInactive = state.user.settings.search_inactive_companies ?? false;
                    this.includeAcquired = state.user.settings.search_acquired_companies ?? true;
                }

                this.loadListStoppers.settings = false;
            }

            if (state.needUpdateStartups || state.needUpdateUsers) {
                this.getList()
            }

            if (state.categories.length && !this.filtersOptions.categories.length) {
                this.filtersOptions.categories = this.arrayHelper.convertForSearch(state.categories)
                this.checkCategoryFilter()
            }
        })

        this.stateWatcher = this.$store.watch(state => state.user.company_type, () => {
            this.getAddLists()
        })

        this.getColumns()
        this.getCategoriesList()
        this.getSavedSearches()
        this.getTopStartupLocationsList()

        for (let name in this.filters) {
            this.$watch('filters.' + name, function () {
                if (!this.notWatchFilters) {
                    this.updateFilterRule(name)
                }
            }, {deep:true})
        }

        let keywords = this.$route.query.keywords

        if (keywords) {
            if (!Array.isArray(keywords)) {
                keywords = [keywords]
            }

            let rules = [];
            let id = 0;

            keywords.forEach(word => {
                id++;
                let keyWord = this.stringHelper.sanitizeUrl(word);

                const rule =
                    {
                        "id"         : id,
                        "label"      : "Keyword",
                        "name"       : "keyword",
                        "value"      : keyWord,
                        "valueAlias" : keyWord,
                        "negative"   : false,
                        "groupWords"   : [],
                        "matchCount" : 1,
                    }

                rules.push(rule);
            })

            if (rules.length === 1) {
                this.prependRule(rules[0]);
            } else {
                this.query.rules = [
                    {
                        'condition': 'OR',
                        'rules': rules,
                    }
                ];
            }
        }

        // retrieve param from url, process and set as initial filter

        let savedSearchId = this.$route.query.query

        if (savedSearchId) {
            this.initSavedSearch = savedSearchId
        } else {
            this.loadListStoppers.querySavedSearch = false
        }

        let tag = this.$route.query.tag

        if (tag === undefined) {
            this.loadListStoppers.queryTag = false
        }

        let category = this.$route.query.category

        if (category === undefined) {
            this.loadListStoppers.queryCategory = false
        }

        let ecosystemName = this.$route.query.ecosystem

        if (ecosystemName !== undefined) {
            let name = decodeURIComponent(ecosystemName)
            let ecosystemFilter = {
                name: name,
                value: name,
            }

            this.filters.ecosystems.push(ecosystemFilter)
        }

        this.loadListStoppers.queryEcosystem = false

        if (this.$store.getters.isFreeEnterprise) {
            this.$store.state.user.connected_ecosystems.forEach(name => {

                let ecosystemFilter = {
                    name: name,
                    value: name,
                }

                if (!this.filters.ecosystems.find(item => item.value === ecosystemFilter.value)) {
                    this.filters.ecosystems.push(ecosystemFilter)
                }
            })
        }

        this.loadListStoppers.connectedEcosystems = false;
    },

    watch: {
        loadListStoppers: {
            deep: true,
            immediate: true,

            handler() {
                if (this.canLoadList) {
                    this.getList()
                }
            }
        },

        trackersList: {
            immediate: true,
            handler(trackersList) {
                if (!this.$store.getters.isStartup) {
                    this.addCellData.trackersList = trackersList
                    this.getTrackersList()
                }
            }
        },

        savedSearch() {
            this.savedSearchApplied = false

            if (this.savedSearch !== null) {
                this.clearFilters()
                this.applySavedSearch()
                this.loadListStoppers.querySavedSearch = false
            }
            else {
                this.canUpdateSavedSearch = false
            }
        },

        query: {
            deep: true,
            handler() {

                if (!this.notWatchQuery) {
                    this.notWatchQuery = true
                    this.normalizeQuery()

                    this.$nextTick(() => {
                        this.notWatchQuery = false
                        this.getList()
                    })
                }

                if (this.savedSearchApplied) {
                    this.canUpdateSavedSearch = true;
                }
            }
        },

        hasInteractions() {
            this.getList()
        },

        includeInactive() {
            this.getList()
        },

        includeAcquired() {
            this.getList()
        },

        notOnCrunchbase() {
            this.getList()
        },

        favorited() {
            this.getList()
        },

        disableDefaultSorting() {
            this.getList()
        },
    },

    methods: {
        ...mapActions(['fetchTrackers','fetchTags']),
        ...mapMutations({
            setSpinnerVisibility: 'search/setSpinnerVisibility',
            setTopClients: 'clients/setTopClients',
        }),

        scoreFilterChanged() {
            this.filters.irlScore = [...this.irlScoreFilterValue]
        },

        getSearchParams() {
            return {
                includeHidden: this.includeHidden,
                hasInteractions: this.hasInteractions,
                includeInactive: this.includeInactive,
                includeAcquired: this.includeAcquired,
                notOnCrunchbase: this.notOnCrunchbase,
                favorited: this.favorited,
                disableDefaultSorting: this.disableDefaultSorting,
            };
        },

        getAddLists() {
            if (this.searchType !== searchTypes.ENTERPRISES && !this.$store.getters.isEcosystem) {
                this.getTagsList()
                this.fetchTrackers()
                this.getTopEcosystemsList()

                if (this.searchType === searchTypes.STARTUPS) {
                    this.getTopClientsList()
                }
            }

            this.loadListStoppers.companyType = false
        },

        getList() {
            if (this.canLoadList) {
                if (this.$store.state.user.show_similar) {
                    let index = this.arrayHelper.findIndexByColumnValue(this.columns, 'name', 'relevancy')

                    if (this.filters.similarTracker) {
                        if (index < 0) {
                            this.columns.splice(1, 0, {
                                label: 'Relevancy',
                                name: 'relevancy',
                            })
                        }
                    } else {
                        if (index >= 0) {
                            this.columns.splice(index, 1)
                        }
                    }
                }

                this.setSpinnerVisibility(true)
                let columns = this.arrayHelper.arrayColumn(this.columns, 'name');
                let currentPage = this.currentPage;
                let pageLength = this.pageLength;
                let sort = this.sort;
                let searchString = this.searchString;

                if (this.customView) {
                    if (this.customView === 'my-startups') {
                        startupsService.getAddedByCompany({columns, sort, currentPage, pageLength, searchString}).then(response => {
                            this.startups = response.data.data
                            this.totalRowsCount = parseInt(response.data.total_count)
                            this.currentPage = response.data.current_page
                            this.loaded = true;
                        }).finally(() => {
                            this.setSpinnerVisibility(false)
                        })
                    }
                } else {
                    let query = this.query
                    let params = this.getSearchParams()
                    let searchType = this.searchType

                    search.getList({
                        searchType,
                        columns,
                        sort,
                        query,
                        currentPage,
                        pageLength,
                        params
                    }).then(response => {
                        console.log(response.data.query)
                        this.startups = response.data.data
                        this.totalRowsCount = parseInt(response.data.total_count)
                        this.currentPage = response.data.current_page
                        this.loaded = true;

                        if ('not_shown_count' in response.data) {
                            this.notShownCount = response.data.not_shown_count;
                        }
                    }).finally(() => {
                        this.setSpinnerVisibility(false)
                    })
                }
            }
        },

        async getTagsList() {
            if (!this.tagsLoaded) {
                await this.fetchTags()

                this.filtersOptions['tags'] = this.arrayHelper.convertForSearch(this.tagsList)

                let tag = this.$route.query.tag

                if (tag !== 'undefined') {
                    let tagItem = this.filtersOptions['tags'].find(item => item.name === tag)

                    if (tagItem) {
                        this.notWatchQuery = true
                        this.filters.tags.push(tagItem)

                        this.$nextTick(() => {
                            this.loadListStoppers.queryTag = false
                            this.notWatchQuery = false
                        })
                    } else {
                        this.loadListStoppers.queryTag = false
                    }
                }
            }
        },

        getTrackersList() {
            trackers.getList().then(response => {
                this.filtersOptions['trackers'] = response.data
                this.filtersOptions['trackers'].unshift({id: 'all', name: 'All Trackers'})
            })

            // trackers.getListForStartupAdd().then(response => {
            //     this.trackersList = response.data
            //     this.addCellData.trackersList = response.data
            // })
        },

        getCategoriesList() {
            this.filtersOptions['categories'] = this.arrayHelper.convertForSearch(this.$store.state.categories)
        },

        getInvestorsList(search) {
            if (search.length) {
                this.loadingInvestors = true;
                investors.getListForSearch(search).then(response => {
                    this.filtersOptions['investors'] = this.arrayHelper.convertForSearch(response.data)
                    this.loadingInvestors = false;
                })
            }
            else {
                this.filtersOptions['investors'] = this.topInvestors
            }
        },

        getTopInvestorsList() {
            this.loadingInvestors = true;
            investors.getTopListForSearch().then(response => {
                this.topInvestors = this.arrayHelper.convertForSearch(response.data)
                this.filtersOptions['investors'] = this.topInvestors
                this.loadingInvestors = false;
            })
        },

        getTopEcosystemsList() {
            ecosystems.getTopListForSearch().then(response => {
                this.topEcosystems = this.arrayHelper.convertForSearch(response.data)
                this.filtersOptions['ecosystems'] = this.topEcosystems
            })
        },

        getTopClientsList() {
            clientsService.getTopListForSearch().then(response => {
                this.topClients = this.arrayHelper.convertForSearch(response.data)
                this.filtersOptions['clients'] = this.topClients
                this.setTopClients(this.topClients);
            })
        },

        updateEcosystemsList(query) {
            if (query.length) {
                ecosystems.getListForSearch(query).then(response => {
                    this.filtersOptions['ecosystems'] = this.arrayHelper.convertForSearch(response.data)
                })
            }
            else {
                this.filtersOptions['ecosystems'] = this.topEcosystems
            }
        },

        updateClientsList(query) {
            if (query.length) {
                clientsService.getListForSearch(query).then(response => {
                    this.filtersOptions['clients'] = this.arrayHelper.convertForSearch(response.data)
                })
            }
            else {
                this.filtersOptions['clients'] = this.topClients
            }
        },

        getTopStartupLocationsList() {
            locations.getTopStartupsLocations().then(response => {
                this.filtersOptions['locations'] = response.data
                this.topStartupLocations = response.data
            })
        },

        updateStartupLocationsList(query) {
            if (query.length) {
                locations.getFilteredStartupsLocations(query).then(response => {
                    this.filtersOptions['locations'] = response.data
                })
            }
            else {
                this.filtersOptions['locations'] = this.topStartupLocations
            }
        },

        getSavedSearches() {
            savedSearches.getList(this.searchType).then(response => {
                this.savedSearches = response.data

                if (this.savedSearch && this.arrayHelper.findIndexByColumnValueInGroup(this.savedSearches, 'items', 'id', this.savedSearch.id ) < 0) {
                    this.savedSearch = null
                }

                if (this.initSavedSearch > 0) {
                    let groupIndex = this.arrayHelper.findIndexByColumnValueInGroup(this.savedSearches, 'items', 'id', this.initSavedSearch)

                    if (groupIndex >= 0) {
                        let itemIndex = this.arrayHelper.findIndexByColumnValue(this.savedSearches[groupIndex]['items'], 'id', this.initSavedSearch)
                        this.savedSearch = this.savedSearches[groupIndex]['items'][itemIndex]
                    }
                    else {
                        window.location.href = "/startups"
                    }
                }
            })
        },

        rulesBackwardCompatibility(rules) {
            let formattedRules = []
            let lastId = 1

            rules.forEach(rule => {
                let item = {}

                if (typeof rule.condition !== "undefined") {
                    item.condition = rule.condition
                    item.rules = []
                    rule.rules.forEach(conditionalRule => {
                        if (conditionalRule.name === "categories") {
                            conditionalRule.value.forEach(category => {
                                let formattedItem = {
                                    "id": lastId++,
                                    "name": "categories",
                                    "label": "Category",
                                    "value": [category],
                                    "valueAlias": category,
                                    "negative": conditionalRule.negative,
                                }

                                item.rules.push(formattedItem)
                            })
                        } else {
                            conditionalRule.id = lastId++
                            item.rules.push({...conditionalRule})
                        }
                    })
                } else {
                    if (rule.name === "categories") {
                        if (rule.value.length > 1) {
                            item.condition = "OR"
                            item.rules = []
                        }

                        rule.value.forEach(category => {
                            let formattedItem = {
                                "id": lastId++,
                                "name": "categories",
                                "label": "Category",
                                "value": [category],
                                "valueAlias": category,
                                "negative": rule.negative,
                            }

                            if (rule.value.length > 1) {
                                item.rules.push(formattedItem)
                            } else {
                                item = formattedItem
                            }
                        })
                    } else {
                        rule.id = lastId++
                        item = {...rule}
                    }
                }

                formattedRules.push(item)
            })

            return formattedRules;
        },

        applySavedSearch() {
            let rules = JSON.parse(this.savedSearch.rules)

            // converts old structure of saved rules to updated one
            rules = this.rulesBackwardCompatibility(rules)

            this.notWatchFilters = true
            this.populateFilters(rules)

            this.$nextTick(() => {
                this.notWatchFilters = false
            })


            this.query.rules = rules
            this.getList()
            this.$nextTick(() => {
                this.savedSearchApplied = true
            })

            savedSearches.updateSearched(this.savedSearch.id)
        },

        populateFilters(rules) {
            this.customFieldsRules = [];
            this.$refs.customDataFilter?.clearFilters();

            rules.forEach(rule => {
                if (typeof rule.condition !== "undefined") {
                    this.populateFilters(rule.rules)
                }
                else {
                    if (!["Keyword"].includes(rule.name)) {
                        this.updateFilter(rule.name, rule.value)
                    }
                }
            })
        },

        clearFilters() {
            for (let key of Object.entries(this.filters)) {
                this.updateFilter(key[0], null)
            }
        },

        updateFilter(name, value) {
            this.notWatchFilters = true

            if (value == null) {
                if (Array.isArray(this.filters[name])) {
                    this.filters[name] = []
                }
                else {
                    if (name === 'name') {
                        this.filters[name] = null
                    }
                }
            }
            else {
                let filtersOptions = this.filtersOptions[name];

                if (name === 'clientsCategories') {
                    filtersOptions = this.filtersOptions['categories']
                }

                if (typeof filtersOptions !== 'undefined') {
                    let values = filtersOptions.filter(item => value.includes(item.value));

                    if (['categories','tags'].includes(name)) {
                        this.filters[name].push(values[0])
                    } else {
                        this.filters[name] = values
                    }

                    if (name === 'locations') {
                        let missingLocations = []
                        value.forEach(item => {
                            if (this.arrayHelper.findIndexByColumnValue(filtersOptions, 'value', item) < 0) {
                                missingLocations.push(item)
                            }
                        })

                        if (missingLocations.length) {
                            locations.getLocationsList(missingLocations).then(response => {
                                response.data.forEach(item => {
                                    filtersOptions.push({
                                        name: item.name,
                                        value: item.value,
                                    })

                                    this.filters[name].push({
                                        name: item.name,
                                        value: item.value,
                                    })
                                })
                            })
                        }
                    }
                }
                else {
                    if (typeof this.showCalendar[name] !== 'undefined') {
                        this.showCalendar[name] = true
                        this.filters[name] = date.encodeInterval(value)
                    } else {
                        this.filters[name] = value

                        if (name === 'irlScore') {
                            this.irlScoreFilterValue = [...value]
                        }
                    }
                }
            }
        },

        clearFilter(name) {
            if (name.search('custom') >= 0) {
                let parts = name.split('_')
                this.$refs.customDataFilter.removeFilterById(parts[1])
                this.removeCustomFieldRuleByName(name)
            }
            else {
                this.notWatchFilters = true

                if (Array.isArray(this.filters[name])) {
                    if (name === 'irlScore') {
                        this.clearIrlScoreFilter()
                    } else {
                        this.filters[name] = [];
                    }
                } else {
                    this.filters[name] = null;
                }

                this.$nextTick(() => {
                    this.notWatchFilters = false
                })
            }
        },

        checkCategoryFilter() {
            if (this.$route.query.category) {
                if (this.filtersOptions.categories.length) {
                    const selectedCategory = this.filtersOptions.categories.find(item => item.value === this.$route.query.category)

                    if (selectedCategory) {
                        this.notWatchQuery = true
                        this.filters.categories.push(selectedCategory)

                        this.$nextTick(() => {
                            this.loadListStoppers.queryCategory = false
                            this.notWatchQuery = false
                        })
                    }
                    else {
                        this.loadListStoppers.queryCategory = false
                    }
                }
            }
        },

        removeCustomFieldRuleByName(name) {
            let index = this.customFieldsRules.indexOf(name)
            if (index >= 0) {
                this.customFieldsRules.splice(index, 1)
            }
        },

        removeFilterFromGroupByValue(groupName, value) {
            this.notWatchFilters = true
            let index = this.arrayHelper.findIndexByColumnValue(this.filters[groupName], 'value', value)
            if (index >= 0) {
                this.filters[groupName].splice(index, 1)
            }

            this.$nextTick(() => {
                this.notWatchFilters = false
            })
        },

        clearAllFilters() {
            this.notWatchFilters = true
            for (let name in this.filters) {
                if (Array.isArray(this.filters[name])) {
                    if (name === 'irlScore') {
                        this.clearIrlScoreFilter()
                    } else {
                        this.filters[name] = [];
                    }
                }
                else {
                    this.filters[name] = null
                }
            }

            this.$refs.customDataFilter.clearFilters();
            this.customFieldsRules = []

            this.$nextTick(() => {
                this.notWatchFilters = false
            })
        },

        clearIrlScoreFilter() {
            this.filters['irlScore'] = [1, 10]
            this.irlScoreFilterValue = [1, 10]
        },

        addKeyword() {
            this.currentKeyword = this.currentKeyword.trim();

            if (this.currentKeyword.length) {
                let keyWord = this.currentKeyword

                if (keyWord.indexOf(':') < 0) {
                    keyWord = this.stringHelper.sanitizeUrl(keyWord);
                }

                var rule =
                    {
                        "label"      : "Keyword",
                        "name"       : "keyword",
                        "value"      : keyWord,
                        "valueAlias" : keyWord,
                        "negative"   : false,
                        "groupWords"   : [],
                        "matchCount" : 1,
                    }

                this.prependRule(rule);
                this.currentKeyword = '';

                if (typeof this.relatedWords[keyWord] == "undefined") {

                    search.getRelatedKeywords(keyWord).then(response => {

                        this.relatedWords[keyWord] = {
                            'sp': [],
                        };

                        response.data['sp'].forEach(word => {
                            if (this.relatedWords[keyWord]['sp'].indexOf(word) < 0) {
                                this.relatedWords[keyWord]['sp'].push(word)
                            }
                        });
                    })
                }
            }
        },

        keywordAutocompleteSubmitted(value) {
            this.currentKeyword = value
            this.addKeyword()
        },

        applySearchOperator(type, value) {
            if (!value.length) {
                value = (type === 'name') ? 'Name: ' : '"Your query"'
                this.$refs.keywordAutocompleter.setValue(value)
            } else {
                let prefix = (type === 'exact-match') ? '"' : 'Name: '
                let suffix = (type === 'exact-match') ? '"' : ''
                this.currentKeyword = prefix + value + suffix
                this.addKeyword()
                this.$refs.keywordAutocompleter.clear()
            }
        },

        openManageColumns() {
            this.$refs.manageColumns.open()
        },

        openCustomDataFilter() {
            this.$refs.customDataFilter.open()
        },

        openSaveSearch() {
            this.$refs.saveSearch.openSave()
        },

        updateSavedSearch() {
            this.$refs.saveSearch.updateQuery(this.savedSearch.id)
            this.canUpdateSavedSearch = false
        },

        savedSearchChanged() {
            this.getSavedSearches()
        },

        openManageSavedSearches() {
            this.$refs.saveSearch.openManage()
        },

        changeSelectedRows(ids) {
            this.selectedRows = ids
        },

        selectAllResults(result) {
            this.selectedAllResults = result
        },

        openAddTags() {
            this.$refs.addTags.open()
        },

        openRemoveTags() {
            this.$refs.removeTags.open()
        },

        openAddToTrackers() {
            this.$refs.addToTrackers.open()
        },
        //openExportContacts() {
        //this.$refs.exportContacts.open()
        //},

        addTags(removedTags) {
            let tagsIds = removedTags.map(item => item.id)
            if (this.selectedAllResults) {
                tags.addTagsByQuery(this.query, this.sort, tagsIds).then(() => {
                    this.$refs.addTags.close()
                })
            }
            else {
                let startups = this.selectedRows
                tags.addTags(startups, tagsIds).then(() => {
                    this.$refs.addTags.close()
                })
            }
        },

        removeTags(addedTags) {
            let tagsIds = addedTags.map(item => item.id)
            if (this.selectedAllResults) {
                tags.removeTagsByQuery(this.query, this.sort, tagsIds).then(() => {
                    this.$refs.removeTags.close()
                    this.getList()
                })
            }
            else {
                let startups = this.selectedRows
                tags.removeTags(startups, tagsIds).then(() => {
                    this.$refs.removeTags.close()
                    this.getList()
                })
            }
        },

        addToTrackers(addedTrackers) {
            let trackersIds = addedTrackers.map(item => item.id)
            if (this.selectedAllResults) {
                let params = this.getSearchParams()
                trackersCompanies.addCompaniesByQuery(this.query, params, this.sort, trackersIds).then(() => {
                    this.getList()
                })
            }
            else {
                let startups = this.selectedRows
                trackersCompanies.addCompanies(trackersIds, startups).then(() => {
                    this.getList()
                })
            }
        },

        exportCSV() {
            let result
            if (this.selectedAllResults) {
                let params = this.getSearchParams()
                result = exportsService.exportCompaniesByQuery(this.searchType, this.query, params, this.sort, this.columns)
            }
            else {
                let startups = this.selectedRows
                result = exportsService.exportCompanies(this.searchType, startups, this.columns)
            }

            result.then(response => {
                this.fileHelper.download(response.data, this.searchType + '.csv')
            })
        },

        openExportPdf() {
            this.$refs.exportPdf.openForMultiple(this.selectedRows, 'startups')
        },

        exportPPT() {
            exportsService.generateStartupsPpt(this.selectedRows).then(response => {
                this.fileHelper.download(response.data, 'startups.pptx')
            })
        },

        exportPPTAdvanced() {
            exportsService.generateStartupsPptAdvanced(this.selectedRows).then(response => {
                this.fileHelper.download(response.data, 'startups.pptx')
            })
        },

        connect() {
            let data = {}
            if (this.selectedAllResults) {
                data.query = this.query
            }
            else {
                data.selected = this.selectedRows
            }

            enterprises.bulkConnect(data).then(response => {
                let message = []

                if (response.data.invited > 0) {
                    message.push("Invited: " + response.data.invited + " " + this.stringHelper.getNoun('enterprise', response.data.invited))
                }

                if (response.data.requested > 0) {
                    message.push("Sent Connection Request for " + response.data.requested + " " + this.stringHelper.getNoun('enterprise', response.data.requested))
                }

                console.log('connect')
                this.getList()

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: message.join(', ')
                })
            })
        },

        editGroup(rule) {
            this.currentRule.id = rule.id
            this.currentRule.value = this.stringHelper.trimSymbol(rule.value, '"')
            this.currentRule.groupWords = rule.groupWords
            this.currentRule.matchCount = rule.matchCount

            this.$refs.manageGroupWords.open()
        },

        saveGroupWords(groupWords, matchCount) {
            this.updateRuleProps(this.currentRule, {groupWords: groupWords, matchCount: matchCount})
        },

        gridAction(params) {
            if (params.action === 'add-tracker') {
                this.addStartupToTracker = params.rowId
                this.$refs.editTracker.open(0, true)
            }
            else if (params.action === 'tag-clicked') {
                let tagIndex = this.arrayHelper.findIndexByColumnValue(this.filtersOptions['tags'], 'name', params.tagName)
                let existsIndex = this.arrayHelper.findIndexByColumnValue(this.filters.tags, 'name', params.tagName)

                if (tagIndex >= 0 && existsIndex < 0) {
                    this.filters.tags.push(this.filtersOptions['tags'][tagIndex])
                }
            }
            else if (params.action === 'invite') {
                this.$emit('invite', params.rowId)
            }
            else if (params.action === 'get-similar') {
                this.$refs.similarStartups.open(params.rowId)
            }
            else if (params.action === 'send-message') {
                this.$refs.startChat.open(params.rowId)
            }
        },

        trackerSaved(trackerId) {
            // this.getTrackersList()
            this.fetchTrackers(true)

            if (this.addStartupToTracker) {
                trackersCompanies.addCompanies([trackerId], [this.addStartupToTracker]).then(() => {

                    startups.getTrackers(this.addStartupToTracker).then(response => {
                        let index = this.arrayHelper.findIndexByColumnValue(this.startups, "id", this.addStartupToTracker)
                        this.startups[index].name.trackersCount = response.data.length

                        this.addStartupToTracker = null
                    })
                })
            }
        },

        getColumns() {
            this.columns = []

            user.getColumns(this.tableColumnsType).then(response => {
                this.$refs.manageColumns.getColumns()

                let columns
                if (this.tableColumnsType === 'search-startups') {
                    columns = response.data.columns
                    this.includeHidden = response.data.params.include_hidden_startups
                }
                else {
                    columns = response.data
                }

                this.columns = []

                for (let i in columns) {
                    let column = columns[i]

                    if (columns[i].name === 'name') {
                        column.component = 'SearchCompanyNameColumn'
                    }

                    if (columns[i].name === 'location') {
                        column.component = 'SearchCompanyLocationColumn'
                    }

                    if (columns[i].name.indexOf('custom_') === 0 || columns[i].name.indexOf('diligence_') === 0) {
                        column.component = 'CustomField'
                    }

                    if (columns[i].name === 'ecosystems') {
                        column.component = 'RawClampColumn'
                    }

                    if (columns[i].name === 'irl_score') {
                        column.component = 'IRLScore'
                    }

                    if (columns[i].name === 'tags') {
                        column.component = 'TagsColumn'
                    }

                    this.columns.push(column)
                }

                this.loadListStoppers.columns = false
                this.getList()
            })
        },

        changeView(viewName) {
            if (viewName === 'radar') {
                this.asideTabIndex = 1
                this.leftCollapsed = false

                let rule = this.query.rules.find(rule => rule.name === 'clients');

                if (rule) {
                    this.$refs.radarSettings.setCategory('client', rule.value);
                }
                this.displayType = viewName
            } else {
                this.displayType = viewName
                this.asideTabIndex = 0
            }
        },

        trackerFilterSelected(selectedOption) {
            if (selectedOption.value !== 'all') {
                if (this.filters.trackers.length === 1 && this.filters.trackers[0].value === 'all') {
                    this.filters.trackers = []
                }

                this.filters.trackers.push(selectedOption)
            } else {
                this.filters.trackers = [selectedOption]
            }
        },

        removeSelectedFilter(removedOption) {
            const removedFilterIndex = this.filters.trackers.findIndex(tracker => tracker.id === removedOption.id)

            if (removedFilterIndex !== undefined) {
                this.filters.trackers.splice(removedFilterIndex, 1)
            }
        },

        showHelpModal() {
            this.$bvModal.show('modal-show-help')
        },

        showAnalystMessageForm() {
            this.$refs.analystMessageModal.show()
        },

        showContactUsMessageForm() {
            this.$refs.contactUsMessageModal.show()
        },

        showUpgradeModal() {
            this.$bvModal.show('modal-show-upgrade');
        },
    },

    beforeDestroy() {
        this.stateWatcher()
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
