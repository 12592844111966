<template>
  <div id="app" :class="{'scrolled': scrolled}">
    <header class="header" v-if="$store.getters.isLoggedIn">
        <div class="header__navigation">
          <div v-if="this.$store.state.user.company_id === this.constants.companyColgate || this.$store.state.user.company_id === this.constants.companyChickFilA" class="logos-set">
            <span class="logos-set__item">
              <router-link :to="homePage" class="logo" title="Switch Pitch"></router-link>
            </span>
            <img v-if="this.$store.state.user.company_id === this.constants.companyColgate" src="../images/colgate-logo.png" width="56" style="width: 56px;" alt="Colgate"/>
            <img v-if="this.$store.state.user.company_id === this.constants.companyChickFilA" src="../images/chick-fil-a-logo.png" width="32" style="width: 32px;" alt="Chick-fil-A"/>
          </div>

          <router-link v-if="this.$store.state.user.company_id !== this.constants.companyColgate && this.$store.state.user.company_id !== this.constants.companyChickFilA" :to="homePage" class="logo" title="Switch Pitch"></router-link>

            <ul class="menu">
                <template v-if="$store.state.user.company_type === 'enterprise'">
                    <li class="menu__home"><router-link to="/" exact><span>Home</span></router-link></li>
                    <li class="menu__activity" v-if="$store.state.accountType === 'premium'"><router-link to="/insights" exact><span>Insights</span></router-link></li>
                    <li class="menu__activity" v-else><a @click="showNeedUpgrade('insights')"><span>Insights</span></a></li>
                    <!-- Deprecated Activity page
                    <template v-if="$store.state.accountType === 'premium'">
                        <li class="menu__activity" v-if="$store.state.isAdmin || $store.state.user.settings.users_feed_access !== 'disabled'"><router-link to="/activity"><span>Activity</span></router-link></li>
                    </template>
                    <li class="menu__activity" v-else><a @click="showNeedUpgrade('activity')"><span>Activity</span></a></li>
                    -->
                    <li class="menu__startups" v-if="$store.state.accountType === 'premium' || $store.state.user.connected_ecosystems.length"><router-link to="/startups"><span>Startups</span></router-link></li>
                    <li class="menu__startups" v-else><a @click="showNeedUpgrade('startups-search')"><span>Startups</span></a></li>
                    <li class="menu__ecosystems" v-if="$store.state.accountType === 'premium'"><router-link to="/ecosystems"><span>Ecosystems</span></router-link></li>
                    <li class="menu__ecosystems" v-else-if="$store.state.user.connected_ecosystems.length"><router-link to="/ecosystems/my-ecosystems"><span>Ecosystems</span></router-link></li>
                    <li class="menu__track"><router-link to="/trackers"><span>Track</span></router-link></li>
                    <li class="menu__resources"><router-link to="/resources"><span>Resources</span></router-link></li>
                    <!-- <li class="menu__more menu__hidden" data-width="80">
                        <a href="#">More</a>
                        <div class="menu__more__inner">
                            <ul>
                                <li class="menu__resources"><router-link to="/resources"><span>Resources</span></router-link></li>
                            </ul>
                        </div>
                    </li> -->
                </template>

                <template v-if="$store.state.user.company_type === 'ecosystem' && $store.getters.isApproved">
                    <li class="menu__home"><router-link to="/" exact><span>Home</span></router-link></li>
                    <li v-if="$store.state.accountType === 'invited'" class="menu__enterprises"><router-link to="/enterprises/connected"><span>Enterprises</span></router-link></li>
                    <li v-else class="menu__enterprises"><router-link to="/enterprises"><span>Enterprises</span></router-link></li>
                    <li v-if="['premium','trial'].includes($store.state.accountType)" class="menu__startups"><router-link to="/find-startups"><span>Startups</span></router-link></li>
                    <li v-else class="menu__startups"><router-link to="/my-startups"><span>My Startups</span></router-link></li>
                    <li class="menu__track" v-if="$store.state.user.has_trackers && false"><router-link to="/projects"><span>Projects</span></router-link></li>
                    <li v-if="$store.getters.isApproved" class="menu__track"><router-link to="/opportunities"><span>Opportunities</span></router-link></li>
                    <li v-if="$store.getters.isPremiumEcosystem" class="menu__track"><router-link to="/trackers"><span>Track</span></router-link></li>
                </template>

                <template v-if="$store.state.user.company_type === 'startup'">
                    <li class="menu__home"><router-link to="/" exact><span>My Company</span></router-link></li>
                    <li v-if="$store.state.user.hasAccessToEnterprises" class="menu__enterprises"><router-link to="/enterprises"><span>Enterprises</span></router-link></li>
                    <li class="menu__track"><router-link to="/public-trackers"><span>Apply To Challenge</span></router-link></li>
                </template>

<!--                <li class="menu__track" v-if="!$store.getters.isLoggedIn"><router-link to="/register/enterprise">Register Enterprise</router-link></li>-->

            </ul>
        </div>

        <div class="header__side">
            <template v-if="$store.getters.isEnterprise">
                <div v-if=false class="header__form-search form-search">
                    <input placeholder="Search" type="text" v-model="searchWord" @keyup.enter="searchByWord"/>
                </div>

                <b-dropdown v-if="$store.state.user.name.length && $store.state.canLoadGlobalComponents" id="dropdown-add" text="Add" class="add-plate">
                    <b-dropdown-item class="add-user" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0" @click="$refs.addUser.open(0)"><span>Add User</span></b-dropdown-item>
                    <b-dropdown-item class="add-startup" @click="$refs.addStartup.open()"><span>Add Startup</span></b-dropdown-item>
                    <b-dropdown-item class="add-ecosystem" @click="$refs.addEcosystem.open()"><span>Add Ecosystem</span></b-dropdown-item>
                    <b-dropdown-item class="add-tracker" v-if="$store.state.user.permissions.indexOf('tracker-create') >= 0" @click="$refs.addTracker.open(0)"><span>Add Tracker</span></b-dropdown-item>
                    <b-dropdown-item class="add-task" @click="$refs.addTask.open(0)"><span>Add Task</span></b-dropdown-item>
                </b-dropdown>

                <template v-if="$store.state.accountType === 'premium'">
                    <div id="top-search" class="top-search" v-b-modal.top-search-modal></div>
                    <b-modal id="top-search-modal"  size="lg" @shown="focusSearchInput" hide-header-close hide-footer hide-title centered>
                        <div class="header__form-search form-search">
                            <input placeholder="Search" type="text" v-model="searchWord" ref="focusThis" @keyup.enter="searchByWord"/>
                            <select v-model="searchType">
                                <option value="startups">Startups</option>
                                <option value="ecosystems">Ecosystems</option>
                            </select>
                        </div>
                    </b-modal>
                </template>

            </template>

            <template v-if="$store.getters.isEcosystem && $store.getters.isApproved">
                <b-dropdown v-if="$store.state.user.name.length" id="dropdown-add" text="Add" class="add-plate">
                    <b-dropdown-item v-if="$store.state.user.is_admin" class="add-user" @click="$refs.addUser.open(0)"><span>Add User</span></b-dropdown-item>
                    <b-dropdown-item class="add-startup" @click="$refs.addStartup.open()"><span>Add Startup</span></b-dropdown-item>
                    <b-dropdown-item class="add-ecosystem" @click="$refs.addEnterprise.open()"><span>Add Connection</span></b-dropdown-item>
                </b-dropdown>
            </template>

            <message-center></message-center>

            <!--<div class="header__add-plate">
                <a class="header__add-plate__link" href="#">Add</a>
            </div>-->
            <div class="header__user" :title=$store.state.user.name>
              <avatar
                  :username="`${$store.state.user.name}`"
                  :src="$store.state.user.avatar"
                  :size="40">
              </avatar>

              <b-dropdown v-if="$store.state.user.name.length" class="topbar__side__actions">
                  <b-dropdown-item class="action" @click="showProfile">
                      <font-awesome-icon :icon="['fa', 'user']" />
                      My profile
                  </b-dropdown-item>

                  <b-dropdown-item v-if="$store.state.user.is_admin && $store.state.companyType !== 'startup'" class="action icon-building" @click="openCompanyProfile">
                      Company profile
                  </b-dropdown-item>

                  <b-dropdown-item v-if="
                      ($store.state.companyType === 'enterprise' && ($store.state.user.permissions.indexOf('team-manage') >= 0 || $store.state.user.admin_groups.length)) ||
                      ($store.state.companyType === 'ecosystem' && ($store.state.user.is_admin) && $store.getters.isApproved) ||
                      ($store.state.companyType === 'startup' && ($store.state.user.is_admin))" class="action icon-team" href="/team">
                      Team
                  </b-dropdown-item>

                  <template v-if="$store.getters.isEnterprise">
                      <b-dropdown-item class="action" href="/settings">
                      <!--<b-dropdown-item class="action" href="/settings/notifications">-->
                          <font-awesome-icon :icon="['fa', 'cog']" />
                          Settings
                      </b-dropdown-item>

                      <b-dropdown-item v-if="$store.state.user.is_admin && $store.state.user.settings.show_fields_history" class="action icon-logs" href="/logs/fields-history">
                          Logs
                      </b-dropdown-item>

                      <!-- Hide Admin menu item, because we have link from settings
                      <b-dropdown-item v-if="$store.state.user.is_admin" class="action icon-admin" href="/admin/permissions">
                          Admin
                      </b-dropdown-item>
                      -->
                      <!--Hide Knowledge base, because everything placed on separate page - Resources
                      <b-dropdown-item class="action icon-note" target="_blank" href="https://switchpitch.document360.io/">
                          Knowledge Base
                      </b-dropdown-item>
                      -->

                      <b-dropdown-item class="action" href="https://switchpitch.atlassian.net/servicedesk/customer/portals">
                          <font-awesome-icon :icon="['fa', 'question-circle']" />
                          Help
                      </b-dropdown-item>
                  </template>

                  <template v-if="$store.getters.isEcosystem">
                      <b-dropdown-item class="action" href="/settings/account">
                          <font-awesome-icon :icon="['fa', 'cog']" />
                          Settings
                      </b-dropdown-item>
                  </template>

                  <b-dropdown-item class="action" href="" @click="logout()">
                    <font-awesome-icon :icon="['fa', 'sign-out-alt']" />
                    Sign Out
                  </b-dropdown-item>
              </b-dropdown>

            </div>
            <!--div class="header__logout" v-if="$store.getters.isLoggedIn">
              <a href="" @click="logout">
                <svg style="width: 20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" class="svg-inline--fa fa-sign-out-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
              </a>
            </div-->

        </div>
        <!--div class="header__notifications">
           <h3 class="heading3">Notifications</h3>
        </div-->

        <template v-if="$store.state.user.company_type === 'enterprise' || $store.state.user.company_type === 'ecosystem'">
            <add-startup
                v-if="$store.state.canLoadGlobalComponents"
                ref="addStartup"
                  prefix="global"
                v-on:startupsAdded="updateStartups"
            >
            </add-startup>
        </template>

        <template v-if="$store.state.user.company_type === 'enterprise'">
            <add-connection
                v-if="$store.state.canLoadGlobalComponents"
                company-type="ecosystem"
                ref="addEcosystem"
            >
            </add-connection>

            <edit-tracker
                v-if="$store.state.canLoadGlobalComponents"
                ref="addTracker"
                prefix="-header"
                :folder-id="0"
                v-on:trackerSaved="updateTrackers"
            >
            </edit-tracker>

            <edit-task
                v-if="$store.state.canLoadGlobalComponents"
                ref="addTask"
                prefix="-header"
            >
            </edit-task>
        </template>

        <template v-if="$store.state.user.company_type === 'ecosystem'">
            <add-connection
                company-type="enterprise"
                ref="addEnterprise"
            >
            </add-connection>
        </template>

        <edit-user
            v-if="($store.state.user.permissions.indexOf('team-manage') >= 0 && $store.state.canLoadGlobalComponents) || $store.state.companyType === 'ecosystem'"
            ref="addUser"
            prefix="-header"
        >
        </edit-user>

    </header>

    <edit-user
        ref="editProfile"
        :profile="true"
        prefix="-profile"
        v-on:user-saved="getUserData(); updateUsers()"
        v-on:close="userSaved()"
    >
    </edit-user>

    <company-profile
        ref="editCompanyProfile"
        v-on:saved="getUserData"
    >
    </company-profile>

    <page-not-found v-if="$store.state.pageNotFound"></page-not-found>

    <router-view
        v-else
        v-on:showProfile="showProfile"
        v-on:showTrialExpired="showTrialExpired"
        v-on:login="getUserData"
    />

    <ecosystem-need-upgrade v-if="$store.state.user.company_type === 'ecosystem'"></ecosystem-need-upgrade>
    <enterprise-need-upgrade v-if="$store.state.user.company_type === 'enterprise'"></enterprise-need-upgrade>
    <need-accept-user-terms ref="acceptTermsModal"></need-accept-user-terms>
      <notifications position="bottom center" width="70%" group="app"/>
    <upgrade v-if="$store.state.user.company_type === 'ecosystem'"></upgrade>
    <trial-expired ref="trialExpired"></trial-expired>

      <select-lessons v-if="!$store.getters.isFreeEnterprise" ref="selectLessonsModal"/>

  </div>
</template>

<script>

import user from '@/services/user.service';
import auth from "@/services/auth.service";
import scroll from '@/mixins/scroll'
import Avatar from 'vue-avatar'
import CompanyProfile from "@/components/company/Profile";
import PageNotFound from "@/components/common/PageNotFound";
import companyProfile from "@/components/CompanyProfile";
import categories from "@/services/categories.service";
import redirectRules from "@/constants/redirect-rules";
import EcosystemNeedUpgrade from "@/components/ecosystem/NeedUpgrade";
import EnterpriseNeedUpgrade from "@/components/enterprise/NeedUpgrade";
import Upgrade from "@/components/subscriptions/Upgrade";
import TrialExpired from "@/components/subscriptions/TrialExpired";
import { baseURL } from "@/axios";
import { mapActions } from "vuex";
import accountTypes from "@/constants/accountTypes";
import NeedAcceptUserTerms from "@/components/enterprise/NeedAcceptUserTerms.vue";
import SelectLessons from "@/components/lessons/SelectLessons.vue";
import {lessonsCodes} from "@/constants/lessons";

export default {
    components: {
        SelectLessons,
        NeedAcceptUserTerms,
        Avatar,
        editTask: () => import("@/components/tasks/EditTask"),
        CompanyProfile,
        PageNotFound,
        companyProfile,
        EcosystemNeedUpgrade,
        EnterpriseNeedUpgrade,
        Upgrade,
        TrialExpired,
        editUser: () => import("@/components/team/EditUser"),
        addStartup: () => import("@/components/startups/AddStartup"),
        editTracker: () => import("@/components/trackers/EditTracker"),
        addConnection: () => import("@/components/connections/AddConnection"),
        MessageCenter: () => import("@/views/messages/MessageCenter"),
    },

    mixins: [
        scroll
    ],

    data() {
        return {
            searchWord: "",
            showCompanyProfile: false,
            hasNewMessages: false,
            checkMessagesTimer: null,
            searchType: 'startups',
        }
    },

    mounted() {
        if (this.$store.getters.isLoggedIn) {
            //Check if token is valid
            auth.checkToken()
                .then((response) => {
                    if (response.data.status !== 1) {
                        this.logout();
                    }
                    else {
                        this.getUserData();
                    }
                })
        }

        this.$store.watch(state => {
            if (state.addStartup) {
                this.$refs.addStartup.open()
            }

            if (state.addEcosystem) {
                this.$refs.addEcosystem.open()
            }

            if (state.addEnterprise) {
                this.$refs.addEnterprise.open()
            }

            if (state.openCompanyProfile) {
                this.openCompanyProfile()
            }

            if (state.needLogout) {
                this.$store.commit('setNeedLogout', false)
                this.logout(true)
            }

            if (this.$store.getters.isLoggedIn) {
                this.checkCanLoadComponents()
            }

            if (!state.categories.length) {
                categories.getList().then(response => {
                    this.$store.commit('setCategories', response.data);
                })
            }

            if (state.needAcceptTerms) {
                this.$refs.acceptTermsModal.open();
            }

            if (state.needUpdateUserData) {
                this.getUserData();
            }
        })
    },

    watch:{
        $route() {
            this.checkCanLoadComponents()
        },
    },

    computed: {
        homePage() {
            if (this.$store.state.user.company_type === 'startup') {
                return "/public-trackers"
            }
            else {
                return "/"
            }
        }
    },

    methods: {
        ...mapActions(["fetchTrackers", "fetchFoldersTrackers"]),
        checkCanLoadComponents() {
            if (this.$route.path !== "/" || (this.$store.state.user.company_type && this.$store.state.user.company_type !== "enterprise") ) {
                this.$store.commit('setCanLoadGlobalComponents', true)

                if (!this.$store.state.categories.length && this.$store.getters.isLoggedIn) {
                    categories.getList().then(response => {
                        this.$store.commit('setCategories', response.data)
                    })
                }
            }
        },

        logout(toCurrent = false) {

            var url = "/login?returnTo=" + this.$router.currentRoute.path

            if (this.$store.state.user.company_type === 'enterprise' && toCurrent) {
                let email = this.$store.state.user.email

                let domainParts = email.split('@')
                let domainName = domainParts[1]

                if (typeof redirectRules.rules[domainName] !== 'undefined' && redirectRules.exceptions.indexOf(email) < 0) {
                    url = baseURL + redirectRules.rules[domainName] + "?returnTo=" + this.$router.currentRoute.path
                }
            }

            clearInterval(this.checkMessagesTimer)
            this.checkMessagesTimer = null
            this.$store.commit('logout')
            this.$store.commit('setPageNotFound', false)
            this.$store.commit('clearTags')
            this.$store.commit('clearRoles')
            this.$store.commit('clearTrackers')

            if (toCurrent) {
                window.location.href = url
            }
            else {
                this.$router.push('/login')
            }
        },

        getUserData() {
            user.getCurrentUserData().then(response => {
                /* Walkme define user details */
                let userDetails = new Object();
                userDetails.userID = response.data.id;
                userDetails.companyID = response.data.company_id;
                userDetails.companyName = response.data.company_name;
                userDetails.name = response.data.name;
                window.userDetails = userDetails;

                this.$gtag.config({ user_id: userDetails.userID })

                this.$store.commit('setUser', response.data)

                if (this.$store.state.user.show_company_profile_form) {
                    this.$refs.editCompanyProfile.open()
                }

                if (this.$store.state.user.company_type === 'enterprise' || this.$store.state.user.company_type === 'ecosystem') {
                    window.dataLayer.push({
                        identity: {
                            'account': {
                                id: this.$store.state.user.company_id,
                                name: this.$store.state.user.company_name,
                                created_at: this.$store.state.user.company_created_at,
                                accountType: this.$store.state.realAccountType
                            },
                            'user': {
                                id: this.$store.state.user.id,
                                email: this.$store.state.user.email,
                                fullName: this.$store.state.user.name,
                                group: this.$store.state.user.groups,
                                created_at: this.$store.state.user.created_at
                            }
                        }
                    })

                    window.dataLayer.push({
                        "context": {
                            "impersonate": false,
                            "staging": process.env.NODE_ENV !== 'production',
                            "platform":"web",
                        }
                    })
                }

                let visitor = {
                    id: this.$store.state.user.id + (process.env.NODE_ENV !== 'production' ? '_test' : '' ),   // Required if user is logged in, default creates anonymous ID
                    email: this.$store.state.user.email,        // Recommended if using Pendo Feedback, or NPS Email
                    full_name: this.$store.state.user.name,
                    is_admin:  this.$store.state.isAdmin,
                    impersonated: this.$store.state.user.impersonated,
                }

                let account = {
                    id: this.$store.state.user.company_id + (process.env.NODE_ENV !== 'production' ? '_test' : '' ), // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                    company_type: this.$store.state.user.company_type,
                    name: this.$store.state.user.company_name,         // Optional
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    creationDate:this.$store.state.user.company_created_at // Optional
                }

                if (this.$store.state.user.company_type === accountTypes.ENTERPRISE) {
                    visitor.group = this.$store.state.user.groups;
                    account.is_paying = this.$store.state.realAccountType === 'premium';
                    account.sub_type = this.$store.state.realAccountType;
                    account.has_connected_vc = !!this.$store.state.user.connected_ecosystems.length;
                }

                window.pendo.initialize({
                    guides: {
                        globalScripts: [
                            {
                                script: function(step, guide) {
                                    for (let number in lessonsCodes) {
                                        if (lessonsCodes[number] === guide.id) {
                                            window.app.setLessonWatched(number);
                                        }
                                    }
                                },

                                test: function(step, guide) {
                                    return window.pendo._.findIndex(guide.steps, (s) => s.id === step.id) === guide.steps.length - 1;
                                }
                            },
                        ]
                    }
                });

                window.pendo.updateOptions({
                    visitor: visitor,
                    account: account,
                });

                if (this.$store.state.user.company_type === accountTypes.ENTERPRISE) {
                    if (this.$store.state.user.need_accept_terms) {
                        this.$store.commit('setNeedAcceptTerms', 1)

                        this.$nextTick(() => {
                            this.$store.commit('setNeedAcceptTerms', 0)
                        })
                    }
                }

                if (!this.$store.getters.isFreeEnterprise && this.$store.state.user.lessons === null) {
                    this.$refs.selectLessonsModal.open();
                }
            })
        },

        searchByWord() {
            window.location.href = '/' + this.searchType + '?keywords=' + this.searchWord
        },

        showProfile(params) {
            this.showCompanyProfile = params.showCompanyProfile
            this.$refs.editProfile.open()
        },

        showTrialExpired(params) {
            this.$refs.trialExpired.open(params.plan)
        },

        updateTrackers() {
            this.$store.commit('setNeedUpdateTrackers', true);

            this.$nextTick(() => {
                this.$store.commit('setNeedUpdateTrackers', false);
            })

            this.fetchTrackers(true)
            this.fetchFoldersTrackers(true)
        },

        updateUsers() {
            this.$store.commit('setNeedUpdateUsers', true);

            this.$nextTick(() => {
                this.$store.commit('setNeedUpdateUsers', false);
            })
        },

        userSaved() {
            this.getUserData()
        },

        updateStartups() {
            this.$store.commit('setNeedUpdateStartups', true);

            this.$nextTick(() => {
                this.$store.commit('setNeedUpdateStartups', false);
            })
        },

        openCompanyProfile() {
            this.$refs.editCompanyProfile.open()
        },

        focusSearchInput() {
            this.$refs.focusThis.focus()
        },
    },
}
</script>
