<template>
    <div class="card card--sm movable" @click="$emit('cardClicked')" :class="{draggable: draggable, 'card--bordered': unapplied}">
        <header class="card__header">
            <div class="image-block image-block--sm">
                <img :src="LogoHelper.getLogo(startup.logoUrl)" alt=""/>
            </div>
            <h5 class="heading5 d-flex">
                <a @click.stop v-if="startup.id" :href="'/startups/' + startup.id" target="_blank">{{ startup.name }}</a>
                <template v-else>{{ startup.name }}</template>
                
                <div class="dropdown dropdown--quaternary" @mouseover="showDropDownMenu = true" @mouseleave="showDropDownMenu = false">
                    <span class="badge dropdown-toggle" :class="{'badge__active': startup.has_account, 'badge__inactive': !startup.has_account}"></span>
                    
                    <div class="dropdown-menu" :class="{'show': showDropDownMenu}">
                        <div class="dropdown-menu__inner">
                            <template v-if="startup.has_account">
                                <p>This startup has an account on SwitchPitch</p>
                            </template>
                            <template v-else>
                                <p>This startup does not have an account on SwitchPitch</p>
                                <a v-if="startup.id" @click.stop="$emit('invite', startup.id)" class="button button--bordered">Invite</a>
                            </template>
                        </div>
                    </div>
                </div>
            </h5>
            
            <!--<div class="card__header__pin"></div>-->
            <div :class="'card__header__pin card__header__pin' + (startup.pinned ? '--active' : '')" @click.stop="() => {$emit('togglePinned')}"></div>
            
            <!-- modal was added only here-->
        </header>
        <template v-if="typeof startup.added_by_ecosystem !== 'undefined'">
            <div class="card__reffered">Referred by <span v-html="startup.added_by_ecosystem.link"></span></div>
        </template>

        <template v-if="typeof startup.added_by_ecosystem !== 'undefined' && typeof startup.added_by_ecosystem.note !== 'undefined'">
            <div v-if="startup.added_by_ecosystem.note" class="card__reffered link" @click="$bvModal.show(`note-modal-${startup.id}`)">Note from <span v-html="startup.added_by_ecosystem.user_name"></span></div>
            <b-modal v-bind:id="`note-modal-${startup.id}`" hide-footer modal-class="note-modal modal-w-lg">
                <div class="modal-header">
                    <div class="image-block image-block--mds">
                        <a target="_blank" :href="'/startups/' + startup.id"><img :src="LogoHelper.getLogo(startup.logoUrl)" :alt="startup.name"/></a>
                    </div>
                    <h3 class="heading3 heading3--secondary mt-3"><a class="mt-2" target="_blank" :href="'/startups/' + startup.id">{{ startup.name }}</a></h3>
                </div>
                <div class="modal-content">
                    <header class="modal-content__header">
                        <div class="image-block image-block--rounded image-block--rounded--mds">
                            <img :src="LogoHelper.getLogo(startup.added_by_ecosystem.user_avatar)" :alt="startup.added_by_ecosystem.user_name" />
                        </div>
                        <div>
                            <p>Note by {{ startup.added_by_ecosystem.user_name }} from <span v-html="startup.added_by_ecosystem.link"></span></p>
                            <a v-if="startup.added_by_ecosystem.user_linkedin" :href="startup.added_by_ecosystem.user_linkedin" target="_blank" class="icon-in"></a>
                        </div>
                    </header>
                    <p>{{ startup.added_by_ecosystem.note }}</p>
                </div>
            </b-modal>
        </template>

        <p v-if="startup.id">{{ startup.description }} (<a @click.stop="getStartupDetails" class="link-click__text" :id="'details-link-'+startup.id">more</a>)</p>
        <p v-else>Account not activated</p>

        <p class="card__counter">{{startup.dates}}</p>

        <a v-if="addData.trackerType === 'public' && !unapplied" @click.stop="$emit('showAnswers')" class="card__answers">
            View Answers
            <time class="card__answers__date">{{ dateHelper.decodeDate(startup.apply_date) }}</time>
        </a>

        <footer v-if="hasReviews" class="card__footer">
            <div class="rating-section" @click.stop="function () { if (startup.interest_level && addData.userPermissions.indexOf('tracker-review-list') >= 0) $emit('showReviews')}">
                <div class="rating">
                    <span :class="{'rating__current': startup.interest_level >= 1}"></span>
                    <span :class="{'rating__current': startup.interest_level >= 2}"></span>
                    <span :class="{'rating__current': startup.interest_level >= 3}"></span>
                    <span :class="{'rating__current': startup.interest_level >= 4}"></span>
                    <span :class="{'rating__current': startup.interest_level >= 5}"></span>
                </div>
                <span class="rating__result" v-if="startup.reviews_count">{{startup.reviews_count}}</span>
            </div>

            <a v-if="startup.user_has_review && addData.userPermissions.indexOf('tracker-review-own-edit') >= 0" class="link" @click.stop="$emit('editReview')">Edit</a>
            <a v-if="!startup.user_has_review && addData.userPermissions.indexOf('tracker-review-add') >= 0" class="link" @click.stop="$emit('editReview')">+ Add</a>
        </footer>

        <footer v-if="unapplied" class="card__footer">
            <p class="card__counter">{{startup.time_ago}}</p>
            <a v-if="startup.id" @click="() => $emit('sendMessage')" class="link link--message">Message</a>
        </footer>

        <b-popover ref="details" :target="'details-link-'+startup.id">
            <div class="popover__content" v-click-outside="closeDetailsPopover">
                <header class="popover__content__header">
                    <div class="popover__content__side">
                        <img class="main-table__logo" :src="LogoHelper.getLogo(startup.logoUrl)" alt="">
                    </div>
                    <div>
                        <h4 class="heading4"><a :href="'/startups/' + startup.id">{{ startup.name }}</a></h4>
                        <p>{{startup.description}}</p>
                    </div>
                </header>

                <div class="popover__content__body">
                    <div class="popover__content__side">
                        <h6 class="heading6">Trackers:</h6>
                    </div>
                    <div>
                        <p>
                            <span v-for="(tracker, index) in startupDetails.trackers" :key="tracker.id">
                                <a :href="'/trackers/' + tracker.id" target="_blank">{{ tracker.name }}</a>
                                <template v-if="index < startupDetails.trackers.length - 1">, </template>
                            </span>
                        </p>
                    </div>
                </div>

                <div class="popover__content__body">
                    <div class="popover__content__side">
                        <h6 class="heading6">Tags:</h6>
                    </div>

                    <div class="tags-list">
                        <span v-for="tag in startupDetails.tags" :key="tag.id" :id="'tag-' + startup.id + '-' + tag.id" class="tags-list__tag" :title="tag.name">
                            {{ tag.name }}
                            <span @click="removeTag(tag.id)" class="tags-list__tag__close"></span>
                            <b-tooltip :target="'tag-' + startup.id + '-' + tag.id" placement="right" variant="light" :title="tag.description"/>
                        </span>

                        <a v-if="!showAddTags" @click.stop="showAddTags = true" class="tags-list__add">+ Add</a>

                        <multiselect v-if="showAddTags"
                                     v-model="addedTags"
                                     :multiple="true"
                                     :options="addData.tagsList"
                                     select-label=""
                                     deselect-label=""
                                     label="name"
                                     track-by="name"
                                     class="multiselect-mid pl-2"
                        >
                        </multiselect>

                        <template v-if="showAddTags">
                            <a v-if="addedTags.length" @click="addTags" class="tags-list__add">+ Add Tags</a>
                            <a @click="showAddTags = false">Cancel</a>
                        </template>
                    </div>
                </div>
            </div>
        </b-popover>
    </div>
</template>

<script>

import startups from "../../services/startups.service";
import multiselect from 'vue-multiselect'
import tags from '../../services/tags.service'

export default {
    props: {
        startup: Object,
        addData: Object,
        draggable: Boolean,
        hasReviews: Boolean,
        unapplied: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        multiselect,
    },
    data() {
        return {
            startupDetails: {
                description: '',
                tags: [],
                trackers: [],
            },

            addedTags: [],
            addedTracker: null,

            showAddTags: false,
            showDropDownMenu: false,
        }
    },

    methods: {
        getStartupDetails() {
            startups.getDetails(this.startup.id).then(response => {
                this.startupDetails = response.data
                this.trackersCount = this.startupDetails.trackers.length
            })
        },

        closeDetailsPopover() {
            this.cancelAddTags()
            this.$refs.details.$emit('close')
        },

        cancelAddTags() {
            this.showAddTags = false
            this.addedTags = []
        },

        addTags() {
            let addedTags = this.arrayHelper.arrayColumn(this.addedTags, 'id')
            let startups = [this.startup.id]
            tags.addTags(startups, addedTags).then(() => {
                this.cancelAddTags()
                this.getStartupDetails()
            })
        },

        removeTag(tagId) {
            let removedTags = [tagId]
            let startups = [this.startup.id]
            tags.removeTags(startups, removedTags).then(() => {
                this.getStartupDetails()
            })
        },
    }
}
</script>

<style scoped>

</style>
