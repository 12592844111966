<template>
    <b-modal id="group-words-modal" title="Keyword Group" size="md" modal-class="group-words-modal">
        <template #modal-title>
            Keyword Group: "{{rule.value}}"
        </template>

        <pulse-loader :loading="!loaded" :color="constants.spinnerColor" class="v-spinner--full"></pulse-loader>

        <ul class="manage-list manage-list--secondary">
            <li v-for="(word, index) in groupWords" :key="index">"{{word}}" <a @click="groupWords.splice(index, 1)" class="remove-action">Remove</a></li>
        </ul>

        <div v-for="(words, type) in relatedWords" :key="type">
            <div class="buttons-list">
                <a class="btn btn-transparent" @click="groupWords.push(word)" v-for="(word, index) in words" :key="index" v-show="groupWords.indexOf(word) < 0">+ {{word}}</a>

                <div v-if="editMode[type]" class="form-control form-control--sm">
                    <input v-model="newWord" @keyup.enter="() => addWord(type)" autofocus>
                    <button @click="() => addWord(type)">+Add</button>
                </div>

                <a class="btn btn-full" v-else @click="editMode[type] = true">+ Add Keyword</a>
            </div>
        </div>

        <template #modal-footer>
            <p v-if="groupWords.length">
                Must match at least
                <select v-model="matchCount" class="selectbox-regular">
                    <option v-for="i in matchArray" :value="i" :key="i">
                        {{i}}
                    </option>
                </select>
                keywords
            </p>

            <b-button variant="primary" size="lg" @click="saveGroupWords">Save</b-button>
        </template>
    </b-modal>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import searchService from "@/services/search.service";

export default {
    props: {
        rule: Object,
        allRelatedWords: Object
    },

    components: {
        PulseLoader
    },

    data() {
        return {
            groupWords: {
                sp: [],
            },

            editMode: {
                sp: false,
            },

            matchCount: 0,
            relatedWords: [],
            loaded: false,
            newWord: "",
        }
    },

    watch: {
        groupWords() {
            if (this.matchCount > this.groupWords.length + 1) {
                this.matchCount = this.groupWords.length + 1
            }
        }
    },

    computed: {
        matchArray() {
            let list = []
            for (let i = 1; i <= this.groupWords.length + 1; ++i) {
                list.push(i)
            }

            return list
        },

        typesLabels() {
            return {
                sp: 'SwitchPitch',
            }
        }
    },

    methods: {
        init() {
            this.relatedWords = []
            this.groupWords = []

            for (let i in this.rule.groupWords) {
                this.groupWords.push(this.rule.groupWords[i])
                this.relatedWords.push(this.rule.groupWords[i])
            }

            this.matchCount = this.rule.matchCount
            this.getRelatedWords()
        },

        open() {
            this.init()
            this.$bvModal.show('group-words-modal')
        },

        saveGroupWords() {
            this.$emit('save-group-words', this.groupWords, this.matchCount);
            this.$bvModal.hide('group-words-modal')
        },

        getRelatedWords() {
            if (typeof this.allRelatedWords[this.rule.value] !== "undefined") {
                this.relatedWords = this.allRelatedWords[this.rule.value]
                this.loaded = true
            } else {
                this.loaded = false

                searchService.getRelatedKeywords(this.rule.value).then(response => {

                response.data['sp'].forEach(word => {
                    if (this.relatedWords['sp'].indexOf(word) < 0) {
                        this.relatedWords['sp'].push(word)
                    }
                });

                    this.loaded = true
                })
            }
        },

        addWord(type) {
            if (this.newWord.length) {
                this.relatedWords[type].push(this.newWord)
                this.groupWords.push(this.newWord)
            }

            this.editMode[type] = false
            this.newWord = ""
        }
    }
}
</script>

<style scoped>

</style>
